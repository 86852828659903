<template>
  <div class="logotoregister">
    <img
      class="inputimg wow animate fadeInUp"
      src="../assets/img/detailimg/register.jpg"
      alt=""
    />
    <div class="CustomerInformation wow animate fadeInUp delay-100ms">
      <div class="anypert">{{ $t("login.registering") }}</div>
      <div class="disclose">
        {{ $t("login.nottoregis") }}
      </div>
      <div class="willboder"></div>
      <div class="personals">
        <div class="styleinfo">{{ $t("login.personal") }}</div>
        <div class="manyinputs">
          <el-input
            :placeholder="kehunews1"
            v-model="new1"
            :class="mobooilep1 ? '' : 'fackending'"
            @blur="mobei1"
          ></el-input>
          <el-input
            :placeholder="kehunews2"
            v-model="new2"
            :class="mobooilep2 ? '' : 'fackending'"
            @blur="mobei2"
          ></el-input>
          <el-input
            :placeholder="kehunews3"
            v-model="new3"
            :class="mobooilep3 ? '' : 'fackending'"
            @blur="mobei3"
          ></el-input>
          <el-input
            :placeholder="kehunews4"
            v-model="new4"
            :class="mobooilep4 ? '' : 'fackending'"
            @blur="mobei4"
          ></el-input>
          <el-input
            :placeholder="kehunews5"
            v-model="new5"
            :class="mobooilep5 ? '' : 'fackending'"
            @blur="mobei5"
          ></el-input>
          <el-input
            :placeholder="kehunews6"
            v-model="new6"
            :class="mobooilep6 ? '' : 'fackending'"
            @blur="mobei6"
          ></el-input>
          <el-input
            :placeholder="kehunews7"
            v-model="new7"
            :class="mobooilep7 ? '' : 'fackending'"
            @blur="mobei7"
          ></el-input>

          <el-input
            :placeholder="kehunews9"
            v-model="new9"
            :class="mobooilep9 ? '' : 'fackending'"
            @blur="mobei9"
          ></el-input>

          <!-- <el-input
            :placeholder="kehunews8"
            v-model="new8"
            :class="mobooilep8 ? '' : 'fackending'"
            @blur="mobei8"
          ></el-input> -->
          <div class="tomide"></div>
        </div>
      </div>
      <div class="willboder willbodertwo"></div>
      <div class="persoopnals">
        <div class="styleinfo">{{ $t("login.toubuy") }}</div>
        <div class="manyinputs">
          <el-select
            v-model="value"
            :placeholder="kehunews10"
            :class="mobooilep10 ? '' : 'fackending'"
            @blur="mobei10"
            @change="rangehood"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="valuetwo"
            :placeholder="kehunews11"
            :class="mobooilep11 ? '' : 'fackending'"
            @blur="mobei11"
            @focus="thirsty"
          >
            <el-option
              v-for="item in optionstwo"
              :key="item.value"
              :label="item.code"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <!-- <div class="hint" style="z-index: 10;position:relative;">
          <img src="../assets/img/detailimg/tishi.png" alt="" />
          </div> -->
          <div class="inputforfather">
            <el-input
              :placeholder="kehunews12"
              suffix-icon="el-icon-question"
              v-model="new10"
              :class="mobooilep12 ? '' : 'fackending'"
              class="input-new0"
              @blur="mobei12"
            >
            </el-input>
            <img
              class="imgscheng"
              src="../assets/img/detailimg/tishi.png"
              alt=""
            />
          </div>

          <!-- <div class="addinputes">
            <img src="../assets/img/detailimg/addthing.png" alt="" />
          </div> -->
          <el-select
            v-model="valuethere"
            :placeholder="kehunews13"
            class="chooseall"
            :class="mobooilep13 ? '' : 'fackending'"
            @blur="mobei13"
          >
            <el-option
              v-for="item in wheretobuy"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <el-select
            v-model="valuefour"
            :placeholder="kehunews14"
            class="deawidinput"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->

          <el-input
            :placeholder="kehunews14"
            class="deawidinput deawidinputtwo"
            v-model="valuefour"
            :class="mobooilep14 ? '' : 'fackending'"
            @blur="mobei14"
          ></el-input>

          <!-- <el-input placeholder="Purchase date" suffix-icon="el-icon-date"> -->
          <div class="block">
            <el-date-picker
              v-model="value1"
              type="date"
              :placeholder="kehunews15"
              :clearable="false"
              :class="mobooilep15 ? '' : 'fackending'"
              @blur="mobei15"
            >
            </el-date-picker>
          </div>
          <!-- </el-input> -->
          <el-input
            :placeholder="kehunews16"
            class="deawidinput deawidinputtwo"
            v-model="new11"
            :class="mobooilep16 ? '' : 'fackending'"
            @blur="mobei16"
          ></el-input>
          <div class="REGISTERNOW" @click="tosubming">
            {{ $t("login.nowtoregister") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import { guarantee, getseriesgoods } from "../request/api";
export default {
  data() {
    return {
      mobooilep1: true,
      mobooilep2: true,
      mobooilep3: true,
      mobooilep4: true,
      mobooilep5: true,
      mobooilep6: true,
      mobooilep7: true,
      mobooilep8: true,
      mobooilep9: true,
      mobooilep10: true,
      mobooilep11: true,
      mobooilep12: true,
      mobooilep13: true,
      mobooilep14: true,
      mobooilep15: true,
      mobooilep16: true,

      value1: "",
      new1: "",
      new2: "",
      new3: "",
      new4: "",
      new5: "",
      new6: "",
      new7: "",
      new8: "",
      new9: "",
      new10: "",
      new11: "",
      options: [
        {
          value: "1",
          label: "Range Hoods",
        },
        {
          value: "2",
          label: "Hobs ",
        },
        {
          value: "3",
          label: "Electric Ovens",
        },
        {
          value: "4",
          label: "Steam Ovens",
        },
        {
          value: "5",
          label: "Microwave Ovens",
        },
        {
          value: "6",
          label: "Dishwasher",
        },
        {
          value: "7",
          label: "other",
        },
      ],

      value: "",
      january: "",
      valuetwo: "",
      valuethere: "",
      valuefour: "",
      optionstwo: [],
    };
  },
  created() {
    console.log(23978489723789);

    this.$bus.$emit("registid", 1);

    setTimeout(() => {
      this.$route.meta.title = this.$t("login.querythere20");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    }, 10);
    this.$bus.$on("add", (message, b, d) => {
      this.outgetseries(message, b);
      this.$route.meta.title = this.$t("login.querythere20");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  computed: {
    wheretobuy: function () {
      return [
        {
          value: "选项1",
          label: this.$t("login.storeing"),
        },
        {
          value: "选项2",
          label: this.$t("login.onlneing"),
        },
        {
          value: "选项3",
          label: this.$t("login.fangdi"),
        },
      ];
    },
    kehunews1() {
      return this.$t("login.kehunews1");
    },
    kehunews2() {
      return this.$t("login.kehunews2");
    },
    kehunews3() {
      return this.$t("login.kehunews3");
    },
    kehunews4() {
      return this.$t("login.kehunews4");
    },
    kehunews5() {
      return this.$t("login.kehunews5");
    },
    kehunews6() {
      return this.$t("login.kehunews6");
    },
    kehunews7() {
      return this.$t("login.kehunews7");
    },
    kehunews8() {
      return this.$t("login.kehunews8");
    },
    kehunews9() {
      return this.$t("login.kehunews9");
    },
    kehunews10() {
      return this.$t("login.kehunews10");
    },
    kehunews11() {
      return this.$t("login.kehunews11");
    },
    kehunews12() {
      return this.$t("login.kehunews12");
    },
    kehunews13() {
      return this.$t("login.kehunews13");
    },
    kehunews14() {
      return this.$t("login.kehunews14");
    },
    kehunews15() {
      return this.$t("login.kehunews15");
    },
    kehunews16() {
      return this.$t("login.kehunews16");
    },
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
  methods: {
    rangehood(e) {
      console.log(e);
      this.january = e;
      this.valuetwo = "";
    },
    outgetseries(a, b, c) {
      getseriesgoods({
        lang: b,
        country: a,
        cate: this.january,
        type: 0,
      }).then((res) => {
        console.log(res);
        this.optionstwo = [...res.data, { code: "other" }];
      });
    },
    thirsty() {
      if (sessionStorage.getItem("newlangusge") == null) {
        this.outgetseries("au", "en", "d");
      } else {
        this.outgetseries("au", sessionStorage.getItem("newlangusge"));
      }
    },
    mobei1() {
      this.mobooilep1 = true;
    },
    mobei2() {
      this.mobooilep2 = true;
    },
    mobei3() {
      this.mobooilep3 = true;
    },
    mobei4() {
      this.mobooilep4 = true;
    },
    mobei5() {
      this.mobooilep5 = true;
    },
    mobei6() {
      this.mobooilep6 = true;
    },
    mobei7() {
      this.mobooilep7 = true;
    },
    mobei8() {
      this.mobooilep8 = true;
    },
    mobei9() {
      this.mobooilep9 = true;
    },
    mobei10() {
      this.mobooilep10 = true;
    },
    mobei11() {
      this.mobooilep11 = true;
    },
    mobei12() {
      this.mobooilep12 = true;
    },
    mobei13() {
      this.mobooilep13 = true;
    },
    mobei14() {
      this.mobooilep14 = true;
    },
    mobei15() {
      this.mobooilep15 = true;
    },
    mobei16() {
      this.mobooilep16 = true;
    },
    tosubming() {
      console.log(11111);
      if (this.new1 == "") {
        this.mobooilep1 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.new2 == "") {
        this.mobooilep2 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.new3 == "") {
        this.mobooilep3 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.new4 == "") {
        this.mobooilep4 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.new5 == "") {
        this.mobooilep5 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.new6 == "") {
        this.mobooilep6 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.new7 == "") {
        this.mobooilep7 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.new8 == "") {
        this.mobooilep8 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.new9 == "") {
        this.mobooilep9 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.value == "") {
        this.mobooilep10 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.valuetwo == "") {
        this.mobooilep11 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.new10 == "") {
        this.mobooilep12 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.valuethere == "") {
        this.mobooilep13 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.valuefour == "") {
        this.mobooilep14 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.value1 == "") {
        this.mobooilep15 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else if (this.new11 == "") {
        this.mobooilep16 = false;

        this.$message({
          message: this.$t("login.borderred"),

          type: "error",
          offset: 90,
        });
      } else {
        if (sessionStorage.getItem("newlangusge") == null) {
          this.otusjj("en");
        } else {
          this.otusjj(sessionStorage.getItem("newlangusge"));
        }
      }
    },
    otusjj() {
      guarantee({
        lang: "en",
        country: "nz",
        family_name: this.new1,
        name: this.new2,
        phone: this.new3,
        email: this.new4,
        address: this.new5,
        house_type: this.new6,
        city: this.new7,
        state: this.new8,
        postcode: this.new9,
        cate_name: this.value,
        code: this.valuetwo,
        serial_number: this.new10,
        channel: this.valuethere,
        channel_describe: this.valuefour,
        buy_date: this.value1,
        order_number: this.new11,
      }).then((res) => {
        console.log(res);
        this.new1 = "";
        this.new2 = "";
        this.new3 = "";
        this.new4 = "";
        this.new5 = "";
        this.new6 = "";
        this.new7 = "";
        this.new8 = "";
        this.new9 = "";
        this.value = "";
        this.valuetwo = "";
        this.new10 = "";
        this.valuethere = "";
        this.valuefour = "";
        this.value1 = "";
        this.new11 = "";
        this.$message({
          message: this.$t("login.sucssfil"),

          type: "success",
          offset: 90,
        });
      });
    },
  },
  beforeDestroy() {
    this.$bus.$emit("registid", 2);
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.logotoregister {
  display: flex;
  flex-direction: column;
  .inputimg {
    width: 100%;
    // height: 500px;
  }
  .CustomerInformation {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 43px;
    .anypert {
      font-size: 28px;
      // font-family: Arial;
      font-weight: 900;
      color: #000000;
    }
    .disclose {
      margin-top: 14px;
      font-size: 14px;
      font-family: Arial;
      font-weight: 400;
      color: #000000;
    }
    .willboder {
      margin-top: 45px;
      // width: 1200px;
      height: 1px;
      background: #f2f2f2;
    }
    .willbodertwo {
      margin-top: 20px;
    }
    .personals {
      display: flex;
      flex-direction: column;
      .styleinfo {
        margin-top: 20px;
        font-size: 18px;
        // font-family: Arial;
        font-weight: bold;
        color: #000000;
      }
      .manyinputs {
        margin-top: 20px;

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .tomide {
          width: 320px;
        }
        /deep/ .el-input {
          width: 320px;
          // height: 60px;
          // margin-bottom: 20px;
          margin-bottom: 24px;
        }
        /deep/ .el-input__inner {
          background: #f2f2f2;
          border: 0px solid #e6e6e6;
          border-radius: 5px;
          height: 38px;
        }
        /deep/ .el-input__inner::placeholder {
          color: #c7c7c7;
        }

        /deep/ .el-input__suffix {
          top: 1px;
          right: 8px;
        }
        /deep/ .el-select__caret {
          -webkit-transform: none;
          transform: none;
        }

        /deep/ .el-icon-arrow-up:before {
          content: "\e790";
        }
        // /deep/.el-input__icon .el-icon-date {
        //   top: 2px;
        // }

        .addinputes {
          width: 1199px;
          height: 40px;
          border: 1px dashed #e6e6e6;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            width: 10px;
            height: 10px;
          }
        }
        .chooseall {
          // margin-top: 20px;
        }
        .deawidinput {
          margin-top: 20px;
          /deep/ .el-input {
            width: 660px !important;
            height: 40px;
            margin-bottom: 20px;
          }
          /deep/ .el-input__inner {
            background: #f5f5f5;
            border: 1px solid #e6e6e6;
            border-radius: 3px;
          }

          /deep/ .el-input__suffix {
            top: 0px;
            right: 8px;
          }
        }
        .deawidinputtwo {
          margin-top: 0px;
          width: 660px;
          /deep/ .el-input {
            height: 60px;
            margin-bottom: 20px;
          }
          /deep/ .el-input__inner {
            background: #f5f5f5;
            border: 0px solid #e6e6e6;
            border-radius: 3px;
          }

          /deep/ .el-input__suffix {
            top: -8px;
            right: 15px;
          }
        }

        /deep/ .el-input--prefix .el-input__inner {
          padding-left: 15px;
        }
        .REGISTERNOW {
          font-size: 12px;
          // font-family: Arial;
          font-weight: bold;
          color: #fff;
          width: 180px;
          height: 48px;
          background: #000000;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          margin-top: 50px;
          margin-bottom: 120px;
          cursor: pointer;
        }
        .REGISTERNOW:hover {
          // border: 1px solid #9e1d22;
          background-size: 14px 10px;
          background-color: #9e1d22;
        }
      }
    }
    .persoopnals {
      display: flex;
      flex-direction: column;
      .styleinfo {
        margin-top: 20px;
        font-size: 18px;
        // font-family: Arial;
        font-weight: bold;
        color: #000000;
      }
      .manyinputs {
        margin-top: 20px;

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        /deep/ .el-input {
          width: 320px;
          // height: 60px;
          // margin-bottom: 20px;
          // margin-bottom: 24px;
        }
        /deep/ .el-input__inner {
          background: #f2f2f2;
          border: 0px solid #e6e6e6;
          border-radius: 5px;
          height: 38px;
        }
        /deep/ .el-input__inner::placeholder {
          color: #c7c7c7;
        }

        /deep/ .el-input__suffix {
          top: 1px;
          right: 8px;
        }
        /deep/ .el-select__caret {
          -webkit-transform: none;
          transform: none;
        }

        /deep/ .el-icon-arrow-up:before {
          content: "\e790";
        }
        // /deep/.el-input__icon .el-icon-date {
        //   top: 2px;
        // }
        /deep/ .el-select {
          margin-bottom: 24px;
        }

        .addinputes {
          width: 1199px;
          height: 40px;
          border: 1px dashed #e6e6e6;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            width: 10px;
            height: 10px;
          }
        }
        .chooseall {
          // margin-top: 20px;
        }
        .deawidinput {
          margin-top: 20px;
          /deep/ .el-input {
            width: 660px !important;
            height: 40px;
            margin-bottom: 20px;
          }
          /deep/ .el-input__inner {
            background: #f5f5f5;
            border: 1px solid #e6e6e6;
            border-radius: 3px;
          }

          /deep/ .el-input__suffix {
            top: 0px;
            right: 8px;
          }
        }
        .deawidinputtwo {
          margin-top: 0px;
          width: 660px;
          /deep/ .el-input {
            height: 60px;
            margin-bottom: 20px;
          }
          /deep/ .el-input__inner {
            background: #f5f5f5;
            border: 0px solid #e6e6e6;
            border-radius: 3px;
          }

          /deep/ .el-input__suffix {
            top: -8px;
            right: 15px;
          }
        }

        /deep/ .el-input--prefix .el-input__inner {
          padding-left: 15px;
        }
        .REGISTERNOW {
          font-size: 12px;
          // font-family: Arial;
          font-weight: bold;
          color: #fff;
          width: 180px;
          height: 48px;
          background: #000000;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          margin-top: 50px;
          margin-bottom: 120px;
          cursor: pointer;
        }
        .REGISTERNOW:hover {
          // border: 1px solid #9e1d22;
          background-size: 14px 10px;
          background-color: #9e1d22;
        }
      }
    }
  }
}
.block {
  /deep/ .el-input__prefix,
  .el-input__suffix {
    position: absolute;
    top: 1px;
    right: -275px;
  }
}
.input-new0 {
  cursor: pointer;
  /deep/ .el-input__suffix {
    color: #93111d;
    // background-color: red;
  }
}
.inputforfather {
  position: relative;

  .imgscheng {
    width: 275px;
    height: 165px;
    position: absolute;
    bottom: 60px;
    right: -20px;
    display: none;
  }
}
.inputforfather:hover .imgscheng {
  display: block;
}
.fackending {
  border: 1px solid red !important;
}
</style>