module.exports = {
	
	language: {
	    name: '中文'
	},



	
	login: {
		title:"中文",
		home: '首页',
		producet: '产品',
		producets: '立即购买',
		serives: '服务',
		news: '新闻',
		about: '关于',
		follow: '联系我们',
		rangehoods: '烟机',
		hobs: '灶具',
		lectricovens: '烤箱',
		steamovens: '蒸箱',
		micovens: '微波炉',
		dishwasher: '水槽洗碗机',
		allproduct:"所有产品",
		faq: 'FAQ',
		manualdownload: '手册下载',
		exhibitionhallquery: '门店查询',
		complaintjoin: '联系方太',
		complaintjoin1: '投诉',
		engineeing: '工程合作',
		aboutfotial: '关于方太',
		happykitchen: '幸福厨房',
		globalout: '全球分布',
		fotilenews : '方太新闻',
		fotilemedia: '方太影像',
		creativemenu : '创意菜谱',
		contentus : '联系我们',
		contentusnow : '马上联系我们',
		cookinglife: '最先进的技术引导您通过快乐烹饪引领革命性的烹饪生活方式',
		allright : '方太海外厨房电器有限公司版权所有',
		sitemap : '网站地图',
		legalnotice : '法律声明',
		globalsite: '全球网站',
		biginning : 'FOTILE方太始终专注于厨房电器领域',
		nowhave : '在新西兰，现拥有吸油烟机、嵌入式灶具、嵌入式微波炉、嵌入式烤箱、嵌入式蒸箱、水槽洗碗机六大产品线。',
		happytochoose : '全球厨房的幸福选择',
		allowaystodo : '方太一直以来都致力于为人们提供高品质的产品和服务，打造健康环保的生活方式，让更多的家庭享受幸福安心的厨房生活。过去20多年来，我们帮助全球各地的家庭改善厨房环境，守护厨房健康。为了亿万家庭的幸福，我们砥砺前行。',
		moreandmore: '更多',
		number1: '方太坚持每年将不少于销售收入的5%投入研发，拥有包含厨房电器领域专家在内的近900余人的研发人才团队。',
		number2: '方太目前在全国已有员工近16000余人，除雄厚的本土设计实力，方太还拥有来自韩、日等地的设计力量以及高端厨房生产设备及国际工业制造先进技术。',
		number3: '并于2013年被国家知识产权局评为第一批国家级知识产权示范企业，积累了数十项厨电产品的核心技术。拥有7200*余件授权专利，其中发明专利超700*件，居行业翘楚地位。',
		number4: '方太拥有国家认定的企业技术中心和中国合格评定国家认可委员会认可的超过12000平方的实验室，同时在德国、日本等地设立研究院。',
		newactive: '资讯活动',
		newmodel: '方太企业推行现代儒家管理模式 ，并积极投身传统文化推广、慈善、环保等公益事业，努力践行企业社会公民责任。',
		newnext: '进一步了解方太',
		newsersh: '专属客服',
		newkonw: '想要了解更多资讯？我们会尽快安排专属客服联系您',
		xielipro: '系列产品',
		xingnum: '产品型号',
		conttype: '电话号码',
		submitsing: '提交',
		ochoosepro: '选择产品',
		ochoosexinghoa: '选择型号',
		contrast: '对比',
		dingyue: '订阅',
		baoxiu: '产品保修注册',
		protobi: '产品对比',
		obempty: '清空',
		osure: '确认',
		mabymost: '最多三种类型比较',
		guige: '规格',
		qubie: '区别',
		shipinvideo: '视频',
		dayijin: '打印机',
		masgomai: '立即购买',
		tobuy: '购买',
		features: '特征',
		prodetail: '产品详情',
		teparnters: '技术参数',
		installation: '安装场景',
		manual: '产品手册',
		tuijianpro: '推荐产品',
		mandetai1: '手册',
		mandetai2: '技术参数',
		mandetai3: '安装指南',
		mandetai4: '保修政策',
		mandetai5: '产品注册',
		mandetai6: '宣传册',
		cannotdoit: '暂无',
		downloadli: '下载',
		serisepro: '方太为您提供各系列产品在使用过程中常见问题解答',
		pleachoose: '请选择',
		ochaxue: '查询',
		allwholeworld: '全球分布',
		wholedetail: '方太在成为世界顶级厨房电器专家的道路上一步步前进，已进入分布于北美、欧洲、澳大利亚、亚洲和非洲的30多个国家。',
		tojoinwant: '留言',
		tothankyou: '有问题可以留言也可以电话，还可以选择我们的线上客服聊天',
		inputname1: '公司名称',
		inputname2: '成立时间',
		inputname3: '公司地址',
		inputname4: '姓名',
		inputname5: '手机',
		inputname6: '邮箱',
		inputname7: '留言',
		inputname8: '主营产品',
		casesone: '认识方太工程',
		casesoneson: '自2005年，方太前瞻性地组建专业的工程营销团队， 全面切入房地产精装修领域。在与众多房地产企业合作的同时， 通过不断挖掘客户需求并进行自我完善和提升， 已经成为拥有众多精装修市场战略客户， 获取众多精装修项目的厨电品牌。 截止目前，方太在全国近两百多个大、中、小城市，配合上千家房地产企业， 完美交付近万个精装修项目， 并与160多家全国知名房地产企业结成战略联盟， 这些骄人的成绩还在不断被刷新！',
		casestwo: '经典项目案例',
		casestwoson: '截止目前，方太在全国近两百多个大、中、小城市，配合上千家房地产企业， 完美交付近万个精装修项目， 并与160多家全国知名房地产企业结成战略联盟， 这些骄人的成绩还在不断被刷新！',
		casesthere: '厨电项目合作',
		casesthereson: '如有合作意向，请填写联系信息，我们将会尽快与您联系',
		oinputname1: '称呼：',
		oinputname2: '公司信息：',
		oinputname3: '语言：',
		oinputname4: '联系电话：',
		oinputname5: '邮箱：',
		Engineering: '认识方太',
		calssing: '工程案例',
		parkservise: '工程无忧服务',
		shouafter: '工程服务流程',
		gorgeous: '我们自有专业工程项目服务团队',
		authority: '从立项勘查、产品安装直止业主交付，全程服务人员均为方太专业工程项目服务人员，具有强大的现场协调能力和标准化服务执行力。',
		lens: '专业项目服务经理跟踪管理',
		erosion: '项目管理、批量施工',
		fatal: '高效的项目管理模式',
		motive: '从施工环境勘查、到货协调、施工质量控制到现场环境对接 具有能够满足客户需求的全过程项目管理体系。',
		cicumstance: '项目负责制',
		embody: '科学高效的项目管理体系',
		mutiply: '与供应链的无缝对接',
		wresetle: '自有服务团队直通全国供应链系统，确保优秀的产品质量，及时的货物交付。',
		pasture: '强大的交付保障',
		approval: '确保到货及时',
		handing: '专业的工程服务现场管理标准',
		morning: '具有行业内严苛的质量控制体系、材料标准和现场管理标准，配合自有服务团队的高效执行力，打造行业内良好且省心的工程服务。',
		handsed: '环境标准',
		hairsing: '施工标准',
		ducking: '材料工具标准',
		bite: '流程标准',
		rabbit: '快速高效的现场反馈机制',
		cakeing: '项目经理负责制，全过程项目管理配合，确保现场反馈快速高效。',
		badanmad: '项目经理制',
		smalling: '全过程全责',
		trutle: '创“3C无忧工程”服务流程',
		weeksing: '用方太灵动、贴心、全面的专业服务来实现让我们的伙伴获得更大价值空间、享受完善合作体验的承诺：',
		jscket: '个性支持',
		shirting: '全心安装',
		sweater: '满意保障',
		hurtsing: '满意保证',
		potato: '业主入住后的任何咨询、维修申请、投诉和建议均可通过呼叫中心处理，开发商无需担心。',
		tomato: '九大系列产品（抽油烟机、灶具、消毒柜、蒸微一体机、蒸笼、烤箱、微波炉、洗碗机、热水器）的维修期均为五年。',
		Presales: '售前',
		Aftersale: '售后',
		Onsale: '售中',
		Presales1: '立项勘察',
		Presales2: '环境校正',
		Presales3: '选型方案',
		Presales4: '预埋烟管',
		Presales11: '专业的服务技术工程师在项目立项前后进行多次环境勘查，及时发现可能影响业主使用体验的环境问题；',
		Presales12: '结合勘查结果和客户需求，为客户提供经济有效的环境校正方案并在校正过程中持续进行免费的环境勘查与服务支持工作；',
		Presales13: '根据客户需求和项目情况，为客户提供多种厨房解决方案，并进行及时专业的技术支持；',
		Presales14: '根据精装进度提供免费的烟管预埋服务，确保业主使用体验。',
		Onsale1: '施工组织方案',
		Onsale2: '现场配合',
		Onsale3: '工厂直供',
		Onsale4: '交付配合',
		Onsale5: '营销支持',
		Onsale11: '施工前完成专业的施工组织方案交底，有效规避施工过程可能遇到的问题，提高现场配合效率；',
		Onsale12: '专业的项目服务工程师全过程协调现场施工，配合现场工作确保施工质量；',
		Onsale13: '工厂直达项目工地的货物供应机制，确保产品品质并有效降低物流带来的货品损耗，保障到货及时；',
		Onsale14: '全过程配合业主交付，提供必要的产品使用说明及服务支持，提高客户的业主满意度；',
		Onsale15: '配合项目营销需求，为业主提供高质量的施工过程标准、产品使用、服务承诺讲解等服务支持。',
		Aftersale1: '全天候的至诚关怀服务',
		Aftersale2: '五年包修',
		Aftersale11: '至诚关怀服务汲取儒家文化的精髓，以“诚”为核心出发点，致力于为客户提供及时、专业、用心的服务体验，让千万家庭“家”的感觉更好；',
		Aftersale12: '油烟机、灶具、消毒柜、蒸微一体机、蒸箱、烤箱、微波炉、水槽洗碗机、热水器产品整机包修5年，业主使用更安心。',
		
		allting: '更多',
		informationing: '资讯&活动',
		squareimage: '方太影像',
		gourmet: '创意菜谱',
		fantings: '过去20多年来，我们帮助全球各地的家庭改善厨房环境，守护厨房健康。为了亿万家庭的幸福，我们砥砺前行。',
		cokintmast: '方太致力于为您提供舒适安心的烹饪空间，为您和家人的健康保驾护航。',
		mostdetail:'详情',
		about1:'全球厨房的幸福选择',
		about11:'方太一直以来都致力于为人们提供高品质的产品和服务，打造健康环保的生活方式，让更多的家庭享受幸福安心的厨房生活。过去20多年来，我们帮助全球各地的家庭改善厨房环境，守护厨房健康。为了亿万家庭的幸福，我们砥砺前行。',
		about2:'方太研发与创新',
		about22:'作为厨电领域的领导者，方太拥有来自世界各地顶尖的研发和设计力量，高端厨房生产设备，国际工业制造先进技术，且在德国、日本和中国等地均设有研发中心。截至 2021 年 3 月，方太拥有 7200 余项授权专利，其中发明专利数量超 700 项，为行业翘楚。雄厚的科研力量，确保了方太的创新实力。2007 年至今，方太共获得28 项 iF 大奖和 22 项 reddot 红点大奖等国际奖项，成为行业佼佼者。',
		about3:'健康厨房生活',
		about33:'方太心系每一位用户的健康。我们坚信，厨房不只是一个完成一日三餐的场所，更是一个家人一起探索烹饪乐趣，分享幸福生活，传递爱与欢乐的空间。每一顿饭也不仅仅是生活所需，更是每一位伟大的家庭厨师生活态度的表达。方太致力于为您提供舒适安心的烹饪空间，为您和家人的健康保驾护航。',
		about4:'行业领导品牌',
		about44:'方太专注于高端厨电的研发与生产，拥有完整的厨电产品线，包括油烟机、灶具、蒸箱、烤箱、水槽洗碗机等，是全球30多个国家超过1700万家庭的首选厨电品牌。截至 2020 年，方太以独特的创新模式、深厚的品牌价值，连续七年荣登亚洲品牌 500 强，位居厨电行业第一。',
		
		registering:'客户信息',
		nottoregis:'方太不会把你的个人信息透漏给任何一方',
		kehunews1:'姓',
		kehunews2:'名',
		kehunews3:'电话号码',
		kehunews4:'电子邮件',
		kehunews5:'地址',
		kehunews6:'公寓/套房/等',
		kehunews7:'城市/城镇',
		kehunews8:'状态',
		kehunews9:'邮政编码',
		kehunews10:'选择类别',
		kehunews11:'选择型号',
		kehunews12:'产品序列号',
		kehunews13:'你在哪里购买方太',
		kehunews14:'请说明你购买的渠道',
		kehunews15:'购买日期',
		kehunews16: '经销商/亚马逊发票/订单号',
		nowtoregister:'现在注册',
		personal:'个人信息',
		toubuy:'购买产品',
		halsthy:'健康厨房生活',
		alwaysanli:'方太一直以来都致力于为人们提供高品质的产品和服务，打造健康环保的生活方式，让更多的家庭享受幸福安心的厨房生活。',
		joninene:'注册订阅方太简报',
		ionudd:'注册我们的邮件，接收新产品提醒、特别优惠和优惠码。',
		
		joininn: '加入',
		seriesing:'系列介绍',
		
		
		food1: '低卡主食 | 培根牛油果三明治',
		food2:'低卡早餐 | 韭菜蛋饼',
		food3:'低卡蔬果汁 | 西蓝花梨汁',
		food4:'深夜食堂 | 热量超低的春雨沙拉',
		food5:'创意美食 | 茼蒿土豆糙米饭团',
		

		newadd1:'热卖产品',
		newadd2: '本周销量最高产品推荐',
		

		feedback1: '名字',
		feedback2: '国家',
		feedback3: '城市',
		feedback4: '电话',
		feedback5: '描述',
		wantfaku:'我想要反馈建议',
		wantfakuson: '对您给予的帮助和支持，深表感谢！',
		tofaku:'建议反馈',


shiname:'FOTILE 是一个使命、愿景和价值驱动的企业。',
		hospion:'主席兼总裁：',

		borderred: '请填写该字段',
sucssfil:'提交成功',

mabuak:'消费者服务',
bighand:'大手册',







storeing: '门店',
		onlneing: '在线',
		fangdi:'房地产',

newthis:'蒸箱菜谱',



querythere1:'方太',
querythere2:'产品列表',
querythere3:'对比',
querythere4:'产品详情',
querythere6:'手册下载',
querythere7:'门店查询',
querythere8:'联系方太',
querythere9:'投诉',
querythere10:'工程合作',
querythere11:'方太新闻',
querythere12:'方太影像',
querythere13:'创意菜谱',
querythere14:'工程合作详情',
querythere15:'方太新闻详情',
querythere16:'方太影像详情',
querythere17:'创意菜谱详情',
querythere18:'关于方太',
querythere19:'幸福厨房',
querythere20:'保修卡',


cancel:'取消',










	}
}
