module.exports = {
	
	language: {
	    name: '印尼'
	},
		
	login: {
		title:'ahshek',
		home: 'Halaman utama',
		producet: 'Produk',
		producets: 'Buy now',
		serives: 'Servis',
		news: 'Baru',
		about: 'Tentang',
		follow: 'Mengikuti',
		rangehoods: 'penghisap asap',
		hobs: 'kompor',
		lectricovens: 'oven listrik',
		steamovens: 'steam oven',
		micovens: 'microwave oven',
		dishwasher: 'dishwasher',
		allproduct:"semua produk ",
		faq: 'FAQ',
		manualdownload: 'unduh katalog',
		exhibitionhallquery: 'pencarian toko',
		complaintjoin: 'Hubungi fotile',
		complaintjoin1: 'keluhan',
		engineeing: 'kerjasama teknik',
		aboutfotial: 'tentang Fotile',
		happykitchen: 'happy kitchen',
		globalout: 'pembagian global',
		fotilenews : 'berita Fotile',
		fotilemedia: 'Medial Fotile',
		creativemenu : 'menu kreatif',
		contentus : 'hubungi kami',
		contentusnow : 'langsung hubungi kami',
		cookinglife: 'Teknologi tercanggih memandu anda menjalani gaya hidup kuliner revisioner melalui happy cooking',
		allright : 'seluruh hak cipta Fotile Overseas Kichen Appliance Co., Ltd dilindungi undang undang',
		sitemap : 'peta situs web',
		legalnotice : 'pemberitahuan hukum',
		globalsite: 'situs web global',
		biginning : 'Fotile selalu fokus pada bidang peralatan dapur',
		nowhave : 'sekarang memiliki 6 macam lini produk yang terdiri dari : penghisap asap, kompor tanam, microwave tanam, oven tanam, steam oven tanam, sink diswasher ',
		happytochoose : 'pilihan bahagia para dapur global',
		allowaystodo : 'Fotile selalu berkomitmen untuk menyediakan produk dan layanan berkualitas tinggi kepada orang - orang, menciptakan gaya hidup yang sehat dan ramah lingkungan, dan memungkinkan lebih banyak keluarga menikmati kehidupan dapur yang aman dan bahagia. Selama 20 tahun terkahir, kita membantu keluarga di seluruh dunia meningkatkan lingkungan dapur dan melindungi kesehatan dapur. Demi kebahagiaan ratuasan juta keluarga, kami akan terus maju.',
		moreandmore: 'lainnya',
		number1: 'Fotile menginvestasikan tidak kurang dari 5% dari penjualan setiap dalam penelitian dan pengembangan produk setiap tahunnya, dan memiliki tim penelitian dan pengembangan lebih dari 900 orang termasuk ahli di bidang peralatan dapur.',
		number2: 'Fotile saat ini sudah memiliki 16000 karyawan di seluruh negeri, selain kekuatan desain lokal yang kuat, Fotile juga memiliki kekuatan desain dari Korea, Jepang, dan tempat lainnya serta memproduksi peralatan dapur kelas atas dan  teknologi manufaktur industri internasional yang canggih ',
		number3: 'dinilai sebagai gelombang pertama perusahaan demonstrasi kekayaan intelektual tingkat nasional Kantor Kekayaan Intelektual Negara, dan telah mengumpulkan puluhan teknologi inti untuk produk peralatan dapur. Memiliki lebih dari 7200 paten resmi, diantaranya termasuk lebih dari 700 paten inovasi, berada pada posisi terdepan di industri.',
		number4: 'Fotile memiliki pusat teknologi perusahaan yang diakui secara nasional dan laboratorium dengan luas elbih dari 12000 meter persegi yang diakui oleh Layanan Akreditasi Nasional China untuk penilaian kesesuaian. Fotile juga telah mendirikan laboratorium penelitian di Jerman, Jepang dan tempat lainnya.',
		newactive: 'Aktivitas informasi',
		newmodel: 'Fotile Enterprise menerapkan model manajemen konfusianisme modern, dan secara aktif berpartisipasi dalam mempromosikan budaya tradisinonal, amal, ramah lingkungan dan usaha kesejahteraan publik lainnya, berusaha untuk mempraktekan tanggung jawab sebagai kewarganegaraan sosial perusahaan.',
		newnext: 'mengetahui Fotile lebih lanjut',
		newsersh: 'Layanan Pelanggan',
		newkonw: 'ingin mengetahui informasi lebih lanjut ? Kami akan mengatur layanan pelanggan untuk menghubungi Anda segera mungkin',
		xielipro: 'seri produk',
		xingnum: 'kode produk',
		conttype: 'kontak informasi',
		submitsing: 'mengirimkan',
		ochoosepro: 'pilih produk',
		ochoosexinghoa: 'pilih kode',
		contrast: 'perbandingan',
		dingyue: 'berlangganan',
		baoxiu: 'kartu garansi',
		protobi: 'perbandingan produk',
		obempty: 'mengosongkan',
		osure: 'yakin',
		mabymost: 'perbandingan paling banyak 3 jenis',
		guige: 'spesifikasi',
		qubie: 'perbedaan',
		shipinvideo: 'video',
		dayijin: 'printer',
		masgomai: 'beli langsung',
		tobuy: 'beli langsung',
		features: 'fitur',
		prodetail: 'rincian produk',
		teparnters: 'parameter teknis',
		installation: 'adegan instalasi',
		manual: 'katalog produk',
		tuijianpro: 'prduk yang direkomendasikan ',
		mandetai1: 'katalog',
		mandetai2: 'parameter teknis',
		mandetai3: 'petunjuk pemasangan',
		mandetai4: 'kebijakan garansi',
		mandetai5: 'registrasi produk',
		mandetai6: 'brosur',
		cannotdoit: 'sementara tidak tersedia',
		downloadli: 'unduh',
		serisepro: 'Fotile memberikan jawaban untuk anda mengenai pertanyaan umum selama pemakaian berbagai rangkaian produk.',
		pleachoose: 'silakan pilih',
		ochaxue: 'mencari',
		allwholeworld: 'distribusi global',
		wholedetail: 'Fotile maju selangkah demi selangkah dalam menjadi ahli peralatan dapur top di dunia, telah memasuki lebih dari 30 negara yang tersebar di Amerika Utara, Eropa, Australia, Asia dan Afrika',
		tojoinwant: 'Saya ingin bergabung dengan Fotile',
		tothankyou: 'Terimakasih telah menghubungi kami, untuk kepuasan Anda, kami akan bekerja 100% !',
		inputname1: 'nama perusahaan',
		inputname2: 'tahun berdiri',
		inputname3: 'alamat perusahaan',
		inputname4: 'name',
		inputname5: 'nomor handphone',
		inputname6: 'email',
		inputname7: 'message',
		inputname8: 'produk utama',
		casesone: 'mengetahui teknik fotile',
		casesoneson: 'sejak tahun 2005, Fotile secara proaktif membentuk tim pemasaran teknik profesional untuk sepenuhnyua memasuki bidang dekorasi real estate. Sementara bekerja sama dengan banyak perusahaan real estate, dengan terus mengeksplorasi kebutuhan pelanggan dalam melakukan perbaikan dan peningkatan diri. Telah menjadi merek peralatan dapur yang memiliki banyak pelanggan strategis di pasar dan telah memperoleh banyak proyek brand peralatan dapur dekorasi bagus. Hingga saat ini, Fotile telah bekerja sama dengan ribuan perusahaan real estate di lebih dari 200 kota besar,kota menengah dan kota kecil di seluruh negeri, dan telah berhasil mengirimkan hampir 10.000 proyek dekorasi yang bagus, dan telah membentuk aliansi strategis dengan lebih dari 160 real estate terkenal. Prestasi yang mengesankan ini masih terus dimuat ulang.',
		casestwo: 'kasus proyek klasik',
		casestwoson: 'Hingga saat ini, di hampir 200 kota besar, kota menengah, dan kota kecil di seluruh negeri, Fotile telah bekerja sama dengan ribuan perusahaan real estate, telah berhasil mengirimkan hampir 10.000 proyek dekorasi yang bagus, dan telah membentuk aliansi strategis dengan lebih dari 160 real estate terkenal. Prestasi yang mengesankan ini masih terus dimuat ulang.',
		casesthere: 'Kerjasama proyek peralatan dapur',
		casesthereson: 'Jika anda tertarik untuk bekerja sama, silakan isi informasi kontak kami dan kami akan menghubungi anda segera mungkin.',
		oinputname1: 'nama lengkap：',
		oinputname2: 'informasi perusahaan：',
		oinputname3: 'bahasa：',
		oinputname4: 'nomor telepon：',
		oinputname5: 'kalamat email：',
		Engineering: 'Mengenal Fotile',
		calssing: 'kasus rekayasa',
		parkservise: 'Rekayasa Layanan bebas khawatir',
		shouafter: 'Proses layanan rekayasa',
		gorgeous: 'kami memiliki tim layanan proyek teknik profesional sendiri',
		authority: 'Dari penyelidikan produk, pemasangan produk hingga pengiriman ke pemilik, seluruh teknisi layanan proyek teknik profesional Fotile, dengan kemampuan berkoordinasi di tempat yang kuat dan kemampuan pelaksanaan layanan standar.',
		lens: 'Manajemen pelacakan manajer layanan profesional ',
		erosion: 'Manajemen proyek, kontruksi batch',
		fatal: 'Model manajemen yang efisien',
		motive: 'Dari survei lingkungan konstruksi, koordinasi pengiriman, kontrol kualitas konstruksi hingga docking lingkungan situs, kami memiliki sistem manajemen proyek untuk seluruh proses yang dapat memenuhi kebutuhan pelanggan.',
		cicumstance: 'Sistem tanggung jawab proyek',
		embody: 'Sistem manajemen proyek yang ilmiah dan efisien',
		mutiply: 'terkoneksi dengan rantai pasokan',
		wresetle: 'tim layanan kami memiliki akses langsung ke sistem rantai pasokan internasional untuk memastikan kualitas produk yang bagus dan pengiriman barang yang tepat waktu',
		pasture: 'Jaminan pengiriman yang kuat',
		approval: 'memastikan pengiriman barang  tepat waktu',
		handing: 'Standar manajemen di tempat yang profesional di layanan teknik industri',
		morning: 'Dengan sistem kontrol kualitas yang ketat, standar material dan standar manajemen di tempat di industri, dengan pelaksanaan yang efisien dari tim layanan kami sendiri, kami menciptakan layanan teknik yang baik dan bebas kekhawatiran di industri.',
		handsed: 'standar lingkungan',
		hairsing: 'standar konstruksi',
		ducking: 'standar alat dan bahan',
		bite: 'standar proses',
		rabbit: 'Mekanisme masukan di tempat yang cepat dan efisien',
		cakeing: 'Manajer proyek bertanggung jawab atas proyek, dan seluruh proses manajemen proyek dikoordinasikan untuk memastikan masukan di tempat yang cepat dan efisien',
		badanmad: 'sistem manajer proyek',
		smalling: 'tanggung jawab penuh untuk seluruh proses',
		trutle: 'membuat proses layanan "proyek bebas khawatir 3C"',
		weeksing: 'Gunakan layanan profesional Fotile yang penuh perhatian dan komprehensif untuk mewujudkan janji membiarkan mitra kami mendapatkan nilai yang lebih besar dan menikmati pengalaman kerja sama yang sempurna',
		jscket: 'dukungan kepribadian',
		shirting: 'Instalasi dengan sepenuh hati',
		sweater: 'Jaminan kepuasan',
		hurtsing: 'menjamin kepuasan',
		potato: '业主入住后的任何咨询、维修申请、投诉和建议均可通过呼叫中心处理，开发商无需担心。',
		tomato: 'Masa garansi sembilan seri produk (penghisap asap, kompor, desinfektan, steam dan microwave two in one, kapal uap, oven, microwave, sink diswasher, water heater) adalah lima tahun.',
		Presales: 'Pra-penjualan',
		Aftersale: 'Setelah Penjualan',
		Onsale: 'saat penjualan',
		Presales1: 'survei proyek',
		Presales2: 'koreksi lingkungan',
		Presales3: 'skema pemilihan',
		Presales4: 'pipa asap terkubur',
		Presales11: 'Insinyur teknisi layanan profesional melakukan beberapa survei lingkungan sebelum dan sesudah proyek didirikan, dan segera menemukan masalah lingkungan yang dapat mempengaruhi pengalaman pemilik',
		Presales12: 'menggabungkan hasil survei dan kebutuhan pelanggan, memberikan solusi koreksi lingkungan yang hemat biaya kepada pelanggan dan terus melakukan survei lingkungan gratis dan dukungan layanan selama proses koreksi',
		Presales13: '根据客户需求和项目情况，为客户提供多种厨房解决方案，并进行及时专业的技术支持；',
		Presales14: 'menyediakan layanan pemasangan pipa gratis sesuai dengan kemajuan hardcover untuk memastikan pengalaman penggunaan pemilik.',
		Onsale1: 'Rencana organisasi konstruksi',
		Onsale2: 'Kerja sama di tempat',
		Onsale3: 'Pasokan langsung pabrik',
		Onsale4: 'Kordinasi pengiriman',
		Onsale5: 'dukungan pemasaran',
		Onsale11: 'menyelesaikan rencana organisasi konstruksi profesional sebelum pengerjaan, secara efektif  menghindari masalah yang mungkin dihadapi dala proses konstruksi, dan meningkatkan efisiensi kerja sama di tempat',
		Onsale12: 'Insinyur teknisi layanan profesional mengoordinasikan konstruksi di tempat selama seluruh proses, dan bekerja sama dengan pekerjaan di tempat untuk memastikan kualitas konstruksi',
		Onsale13: 'Mekanisme pasokan barang dari pabrik langsung ke lokasi proyek, memastikan kualitas produk dan secara efektif mengurangi kerusakan barang yang disebabkan oleh logistik, dan menjamin kedatangan barang tepat waktu',
		Onsale14: 'bekerja sama dengan pemilik selama seluruh proses, memberikan instruksi produk serta dukungan layanan yang diperlukan, meningkatkan kepuasan pemilik pelanggan ',
		Onsale15: 'bekerja sama dengan kebutuhan pemasaran proyek, memberikan standar proses konstruksi berkualitas tinggi kepada pemilik proyek, penggunaan produk, penjelasan komitmen layanan, dan dukungan lainnya',
		Aftersale1: 'layanan perawatan dengan tulus di segala macam cuaca',
		Aftersale2: 'garansi lima tahun',
		Aftersale11: 'layana perawatan yang tulus mengacu pada esesnsi budaya konfusianisme, mengambil "ketulusan" sebagai inti dari titik awal, dan berkomitmen untuk menyediakan pengalaman layanan yang tepat waktu, profesional, dan berdedikasi kepada pelanggan, membuat "rumah" dari puluhan ribu keluarga menjadi lebih baik ',
		Aftersale12: 'masa garansinya penghisap asap, kompor, desinfektan, steam microwave two in one, steam oven, oven, microwafe, sink diswasher, water heater adalah 5 tahun, sehingga lebih aman digunakan.',
		
		allting: 'lainnya',
		informationing: 'informasi & event',
		squareimage: 'Gambar Fotile',
		gourmet: 'Menu kreatif',
		fantings: 'Selama 20 tahun terkahir, kita membantu keluarga di seluruh dunia meningkatkan lingkungan dapur dan melindungi kesehatan dapur. Demi kebahagiaan ratuasan juta keluarga, kami akan terus maju.',
		cokintmast: 'Fotile berkomitmen untuk menyediakan ruang memasak yang aman dan nyaman kepada Anda, untuk melindungi kesehatan Anda dan keluarga Anda',
		mostdetail:'rincian',
		about1:'Pilihan bahagia para dapur global',
		about11:'Fotile selalu berkomitmen untuk menyediakan produk dan layanan berkualitas tinggi kepada orang - orang, menciptakan gaya hidup yang sehat dan ramah lingkungan, dan memungkinkan lebih banyak keluarga menikmati kehidupan dapur yang aman dan bahagia. Selama 20 tahun terkahir, kita membantu keluarga di seluruh dunia meningkatkan lingkungan dapur dan melindungi kesehatan dapur. Demi kebahagiaan ratuasan juta keluarga, kami akan terus maju.',
		about2:'R&D dan inovasi Fotile',
		about22:'Sebagai pemimpin di bidang peralatan dapur, Fotile memiliki kekuatan R&D dan desain terbaik di seluruh dunia, peralatan produksi kelas atas, teknologi manufaktur industri internasional yang canggih, dan memiliki pusat R&D di Jerman, Jepang dan China. Pada Maret 2021, Fotile memiliki lebih dari 7200 paten resmi, diantaranya termasuk lebih dari 700 paten inovasi, menjadikannya sebagai pemimpin industri. Kekuatan penelitian ilmiah yang kuat memastikan kekuatan inovatif Fotile. 2007 hingga sekarang.',
		about3:'Kehidupan dapur yang sehat',
		about33:'Fotile peduli dengan kesehatan setiap pengguna. Kami percaya bahwa dapur bukan hanya tempat makan tiga kali sehari, tetapi juga merupakan ruang bagi keluarga untuk mengeksplorasi kesenangan memasak, menyampaikan cinta dan kegembiraan. Setiap kali makan bukan hanya kebutuhan hidup, tetap juga mengekspresikan sikap hidup setiap chef keluarga yang hebat. Fotile berkomitmen untuk menyediakan ruang memasak yang aman dan nyaman kepada Anda, untuk melindungi kesehatan Anda dan keluarga Anda.',
		about4:'Merek terkemuka di industri',
		about44:'Fotile fokus pada R&D dan produksi peralatan dapur kelas atas, dan memiliki rangkaian lengkap peralatan dapur, termasuk penghisap asap, kompor, steam oven, oven, sink diswasher dan lainnya. Merupakan peralatan dapur pilihan pertama oleh 17juta lebih keluarga di 30 negara di seluruh dunia. Pada tahun 2020, Fotile telah menempati peringkat di dalam 500 merek asia teratas selama tujuh tahun berturut turut dengan model inovatifnya yang unik dan nilai brand yang mendalam.',
		
		registering:'informasi pelanggan',
		nottoregis:'Fotile tidak akan mengungkapkan informasi pribadi Anda kepada pihak manapun',
		kehunews1:'nama belakang',
		kehunews2:'nama depan',
		kehunews3:'nomor telepon',
		kehunews4:'alamat email',
		kehunews5:'alamat',
		kehunews6:'apartemen/rumah/lainnya',
		kehunews7:'kota',
		kehunews8:'status',
		kehunews9:'kode pos',
		kehunews10:'pilih jenis produk',
		kehunews11:'pilih kode produk',
		kehunews12:'nomor seri produk',
		kehunews13:'dimana kamu membeli produk Fotile ?',
		kehunews14:'pilih langsung atau pencarian pilihan',
		kehunews15:'tanggal beli produk',
		kehunews16: 'Reseller/ Invoice Amazon/ Nomor Pesanan',
		nowtoregister:'daftar sekarang',
		personal:'informasi personal',
		toubuy:'beli produk',
		halsthy:'Kehidupan dapur yang sehat',
		alwaysanli:'Fotile selalu berkomitmen untuk menyediakan produk dan layanan berkualitas tinggi kepada orang - orang, menciptakan gaya hidup yang sehat dan ramah lingkungan, dan memungkinkan lebih banyak keluarga menikmati kehidupan dapur yang aman dan bahagia.',
		joninene:'Daftar dan ikuti briefing Fotile',
		ionudd:'Daftarkan ke email kami untuk menerima pengingat produk baru, penawaran khusus, dan kode diskon',
		
		joininn:'bergabung',
		seriesing:'Pengenalan Seri',
		
		newadd1:'top products',
		newadd2:'This week is highest-selling product recommendation',
		
    feedback1: 'name',
		feedback2: 'bangsa',
		feedback3: 'Kota',
		feedback4: 'telepon',
		feedback5: 'masalah',
		wantfaku:'Saya ingin umpan balik dan saran',
		wantfakuson: 'Terima kasih banyak atas bantuan dan dukungan Anda!',
		tofaku:'comment',
		

shiname:'FOTILE adalah perusahaan yang mengejar misi, visi dan nilai-nilai.',
		hospion:'Ketua dan Presiden:',


borderred:'Silahkan isi kolom ini',
sucssfil:'Dikirim dengan sukses',



	}
}
