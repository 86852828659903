<template>
  <div class="constract">
    <div class="constmiddle">
      <div class="selsectp wow animate fadeInUp">
        {{ $t("login.ochoosepro") }}
      </div>
      <div class="upmodel wow animate fadeInUp">
        {{ $t("login.mabymost") }}
      </div>
      <div class="haveimgs wow animate fadeInUp delay-100ms">
        <div v-for="item in chuandata" :key="item.id">
          <div class="stronging">
            <img class="dolling" :src="item.thumb" alt="" />
            <span>{{ item.code }}</span>
            <img
              class="maybechain"
              src="../assets/img/alltitle/maybecha.png"
              alt=""
              @click="showandhid(item.id)"
            />
          </div>
        </div>
        <div
          class="serienumber"
          v-if="chuandata.length == 0 || chuandata.length == 1"
        >
          <div class="rangehd">{{ manycode }}</div>
          <el-select
            v-model="value01"
            :placeholder="placeholder1"
            class="defauk"
            :popper-append-to-body="false"
            @change="rangehoodone"
          >
            <el-option
              v-for="item in optionsranone"
              :key="item.value"
              :label="item.label"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="value011"
            :placeholder="placeholder2"
            class="likenub"
            :popper-append-to-body="false"
            @focus="thirsty(1)"
            @change="botval($event, 1)"
          >
            <el-option
              v-for="item in optionstwo"
              :key="item.value"
              :label="item.label"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <div class="confirmsure" @click="submitconfim(1)">
            {{ $t("login.osure") }}
          </div>
        </div>
        <div
          class="serienumber"
          v-if="chuandata.length == 0 || chuandata.length == 1"
        >
          <div class="rangehd">{{ manycode }}</div>
          <el-select
            v-model="value02"
            :placeholder="placeholder1"
            class="defauk"
            :popper-append-to-body="false"
            @change="rangehoodtwo"
          >
            <el-option
              v-for="item in optionsrantwo"
              :key="item.value"
              :label="item.label"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="value022"
            :placeholder="placeholder2"
            class="likenub"
            :popper-append-to-body="false"
            @focus="thirsty(2)"
            @change="botval($event, 2)"
          >
            <el-option
              v-for="item in optionstwo"
              :key="item.value"
              :label="item.label"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <div class="confirmsure" @click="submitconfim(2)">
            {{ $t("login.osure") }}
          </div>
        </div>
        <div
          class="serienumber"
          v-if="chuandata.length == 0 || chuandata.length == 2"
        >
          <div class="rangehd">{{ manycode }}</div>
          <el-select
            v-model="value03"
            :placeholder="placeholder1"
            class="defauk"
            :popper-append-to-body="false"
            @change="rangehoodthere"
          >
            <el-option
              v-for="item in optionsranthere"
              :key="item.value"
              :label="item.label"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="value033"
            :placeholder="placeholder2"
            class="likenub"
            :popper-append-to-body="false"
            @focus="thirsty(3)"
            @change="botval($event, 3)"
          >
            <el-option
              v-for="item in optionstwo"
              :key="item.value"
              :label="item.label"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <div class="confirmsure" @click="submitconfim(3)">
            {{ $t("login.osure") }}
          </div>
        </div>
      </div>

      <div class="xinaxing wow animate fadeInUp delay-300ms"></div>
      <div class="Specifications wow animate fadeInUp delay-300ms">
        <div class="onechilds">{{ $t("login.guige") }}</div>
        <!-- <div class="doublechulds">{{ $t("login.qubie") }}</div> -->
      </div>
      <div class="tablelist wow animate fadeInUp delay-500ms">
        <el-table
          :data="biaodate"
          border
          :cell-style="rowStyle"
          style="border-radius: 0px"
        >
          <el-table-column
            prop="name_0"
            :label="tabtitle[0].name"
            style="font-size: 44px !important"
          >
          </el-table-column>
          <el-table-column prop="name_1" :label="tabtitle[1].name">
          </el-table-column>
          <el-table-column prop="name_2" :label="tabtitle[2].name">
          </el-table-column>
          <el-table-column prop="name_3" :label="tabtitle[3].name">
          </el-table-column>
        </el-table>
        <!-- <div style="width: 20%">
          <el-table
            :data="param"
            border
            :cell-style="rowStyle"
            style="border-radius: 0px"
          >
            <el-table-column prop="name" label="param"> </el-table-column>
          </el-table>
        </div> -->

        <!-- <div v-for="item in goods" :key="item.id" style="width: 80%">
          <el-table
            :data="item.param"
            border
            :cell-style="rowStyle"
            style="border-radius: 0px"
          >
            <el-table-column prop="content" :label="item.code">
            </el-table-column>
          </el-table>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  getcontrast,
  getseries,
  getseriesgoods,
  get_goods_info,
  newcontrast,
} from "../request/api";

export default {
  components: { quillEditor },

  // data() {
  //   return { content: "", editorOption: {} };
  // },
  // methods: {
  //   submit() {
  //     console.log(this.$refs.text.value);
  //   },
  // },
  // editorOption里是放图片上传配置参数用的，例如：// action:  '/api/product/richtext_img_upload.do',  // 必填参数 图片上传地址// methods: 'post',  // 必填参数 图片上传方式// token: '',  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage// name: 'upload_file',  // 必填参数 文件的参数名// size: 500,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb// accept: 'multipart/form-data, image/png, image/gif, image/jpeg, image/bmp, image/x-icon,image/jpg'  // 可选 可上传的图片格式

  data() {
    return {
      content: "",
      editorOption: {},
      changenumsbengin: [],
      optionsranone: [
        {
          value: "选项1",
          label: "Range Hoods",
        },
        {
          value: "选项2",
          label: "Hobs ",
        },
        {
          value: "选项3",
          label: "Electric Ovens",
        },
        {
          value: "选项4",
          label: "Steam Ovens",
        },
        {
          value: "选项5",
          label: "Microwave Ovens",
        },
        {
          value: "选项6",
          label: "Dishwasher",
        },
      ],
      optionsrantwo: [],
      optionsranthere: [],
      value1: "",
      value01: "",
      value02: "",
      value03: "",
      value011: "",
      value022: "",
      value033: "",
      optionstwo: [],
      valuetwo: "",
      january1: "",
      january2: "",
      january3: "",
      tableData: [],
      param: [],
      // tableData: [
      //   {
      //     date: "Certification",
      //     name: "CB,SAA",
      //     address: "CB,SAA,ETL ",
      //     gender: "CB,SAA",
      //   },
      //   {
      //     date: "Product Size (mm)(W×D×H)",
      //     name: "1200×700×（890-1490）",
      //     address: "900X520X（660-960）",
      //     gender: "900x515x（645-940）",
      //   },
      //   {
      //     date: "Anion Sterilization",
      //     name: "Y",
      //     address: "Y",
      //     gender: "Y",
      //   },
      //   {
      //     date: "Voltage",
      //     name: "220-240V 50/60Hz",
      //     address: "220-240V 50/60Hz；120V 60Hz",
      //     gender: "220-240V 50/60Hz",
      //   },
      //   {
      //     date: "Noise Level (Sones)",
      //     name: "≤56",
      //     address: "≤49",
      //     gender: "48",
      //   },
      //   {
      //     date: "Illumination Power (W*1)",
      //     name: "7X2(LED)",
      //     address: "2(LED)",
      //     gender: "2*2",
      //   },
      //   {
      //     date: "Filter",
      //     name: "Y",
      //     address: "N",
      //     gender: "Y",
      //   },
      //   {
      //     date: "Air Flow (m³/h)",
      //     name: "1450",
      //     address: "1450",
      //     gender: "1200（IEC61591)",
      //   },
      //   {
      //     date: "Motor Power (W)",
      //     name: "242",
      //     address: "210",
      //     gender: "258",
      //   },
      //   {
      //     date: "Auto Turbo System",
      //     name: "N",
      //     address: "N",
      //     gender: "Y",
      //   },
      //   {
      //     date: "Filter",
      //     name: "Clean&Clear Filter",
      //     address: "Corrugated Filter",
      //     gender:
      //       "Wing-liked Surround Suction Plate+ double-layer Clean & Clear filter",
      //   },
      //   {
      //     date: "Net Weight (kg)",
      //     name: "50.6（Mounting Bracket 9.8）",
      //     address: "29.7",
      //     gender: "34.5",
      //   },
      //   {
      //     date: "Smoke Sound Detection",
      //     name: "N",
      //     address: "Y",
      //     gender: "Y",
      //   },
      //   {
      //     date: "Gross Weight (kg)",
      //     name: "69.6（Mounting Bracket 9.8）",
      //     address: "36.6",
      //     gender: "46",
      //   },
      //   {
      //     date: "Packing-Box Size (mm)",
      //     name: "1325×970×805（Mounting Bracket 400×380×1020）",
      //     address: "965X685X575",
      //     gender: "1015×755×592",
      //   },
      //   {
      //     date: "Volume (m³)",
      //     name: "1.035（Mounting Bracket 0.155）",
      //     address: "0.38",
      //     gender: "0.454",
      //   },
      //   {
      //     date: "Central Energy Cursor",
      //     name: "N",
      //     address: "Y",
      //     gender: "Y",
      //   },
      //   {
      //     date: "Intelligent Air Management",
      //     name: "N",
      //     address: "Y",
      //     gender: "N",
      //   },
      // ],
      chuandata: [],
      chooseid: [],
      cloudynum: "",
      kick: [],
      goods: [],

      newlost: [],
      theendid1: "",
      theendid2: "",
      theendid3: "",
      petandarm1: "",
      petandarm2: "",
      petandarm3: "",
      biaodate: [],
      tabtitle: [],
      newfastdata: {},
      manycode: "",
      chuancode: "",
    };
  },
  computed: {
    placeholder1() {
      return this.$t("login.ochoosepro");
    },
    placeholder2() {
      return this.$t("login.ochoosexinghoa");
    },
  },
  created() {
    // console.log(this.$route.query.todata);
    this.chuandata = this.$route.query.todata;
    console.log(this.chuandata);

    this.manycode = this.$route.query.manycode;
    console.log(this.manycode);

    this.chuancode = this.$route.query.chuancode;
    console.log(this.chuancode);

    this.maricmas();
    this.chuandata.forEach((el) => {
      el.showone = 1;
      el.showtwo = 2;
    });
    //
    this.chuandata.forEach((val) => {
      // console.log(val);
      this.chooseid.push(val.id);
    });
    this.$route.meta.title = this.$t("login.querythere3");
    this.$router.replace({
      query: {
        temp: Date.now(), //必有
      },
    });
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      console.log(d);
      this.getnewcontrast(message, b);
      this.outgetseries(message, b, this.chuancode);
      this.$route.meta.title = this.$t("login.querythere3");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });

    if (sessionStorage.getItem("newlangusge") == null) {
      this.getnewcontrast("nz", "en");
      this.outgetseries("nz", "en", this.chuancode);
    } else {
      this.getnewcontrast("nz", sessionStorage.getItem("newlangusge"));
      this.outgetseries(
        "nz",
        sessionStorage.getItem("newlangusge"),
        this.chuancode
      );
    }
  },
  mounted() {
    for (let i = 0; i < 5; i++) {
      // console.log(Math.floor(Math.random() * 10) + 1);
      var manu = Math.floor(Math.random() * 10) + 1;
      this.changenumsbengin.push(manu);
    }
    console.log(this.changenumsbengin);
    this.changenumsbengin.forEach((el) => {
      // console.log(el);
    });
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
  methods: {
    maricmas() {
      if (this.chuandata.length == 1) {
        this.cloudynum = 2;
      } else if (this.chuandata.length == 2) {
        this.cloudynum = 1;
      } else if (this.chuandata.length == 3) {
        this.cloudynum = 0;
      } else if (this.chuandata.length == 0) {
        this.cloudynum = 3;
      }
    },
    submit() {
      console.log(this.$refs.text.value);
    },
    outget_goods_info(a, b, c) {
      get_goods_info({
        code: c,
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        // this.optionstwo = res.data;
        if (res.code == 1) {
          // console.log(res);
          // console.log(res.data.data);
          // this.newfastdata = res.data.data;
          this.chuandata.push(res.data.data);
          // this.chuandata.splice(this.chuandata.length, 1, res.data.data);

          console.log(this.chuandata);
          this.$forceUpdate();
          // this.$set(this.chuandata, 0, res.data.data);

          this.chooseid = [];
          this.chuandata.forEach((val) => {
            // console.log(val);
            this.chooseid.push(val.id);
          });
          // console.log(this.chooseid);
          console.log(b);
          this.getnewcontrast("nz", b);
          this.$forceUpdate();

          // sessionStorage.setItem("newechuandata", this.chuandata);
          // this.chuandata = sessionStorage.getItem("newechuandata");
        }
      });
    },
    outgetseriesgoods(a, b, c, d) {
      getseriesgoods({
        cate: c,
        lang: b,
        country: a,
        type: d,
        id: this.chooseid.join(","),
      }).then((res) => {
        console.log(res);
        this.optionstwo = res.data;
      });
    },
    outgetseries(a, b, c) {
      getseries({
        cate: c,
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.optionsranone = res.data;
        this.optionsrantwo = res.data;
        this.optionsranthere = res.data;
      });
    },
    getnewcontrast(a, b) {
      newcontrast({
        lang: b,
        country: a,
        id: this.chooseid.join(","),
      }).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.param = res.data.param;

        this.param.forEach((el) => {
          this.kick.push({ name: el });
        });
        console.log(this.kick);
        this.param = this.kick;
        // this.param = this.param.slice(0, 20);
        this.goods = res.data.goods;
        console.log(this.goods);

        this.biaodate = res.data.table_data;
        this.tabtitle = res.data.table_title;
        // this.$forceUpdate();
      });
    },
    // geteverynew(a, b) {
    //   getcontrast({
    //     lang: b,
    //     country: a,
    //     id: this.chooseid.join(","),
    //   }).then((res) => {
    //     console.log(res);
    //     this.tableData = res.data;
    //     this.param = res.data.param;

    //     this.param.forEach((el) => {
    //       this.kick.push({ name: el });
    //     });
    //     console.log(this.kick);
    //     this.param = this.kick;
    //     // this.param = this.param.slice(0, 20);
    //     this.goods = res.data.goods;
    //     console.log(this.goods);
    //   });
    // },

    showandhid(id) {
      console.log(id);
      this.chuandata.forEach((el) => {
        if (id == el.id) {
          el.showone = 2;
          el.showtwo = 1;
        }
      });

      // var idex = this.chuandata.indexOf(id.toString());
      // console.log(idex);
      // this.chuandata.splice(idex, 1);

      this.chuandata.forEach((el, index) => {
        if (id == el.id) {
          this.chuandata.splice(index, 1);
        }
      });
      console.log(this.chuandata);
      this.chooseid = [];
      this.chuandata.forEach((val) => {
        // console.log(val);
        this.chooseid.push(val.id);
      });
      console.log(this.chooseid);

      if (sessionStorage.getItem("newlangusge") == null) {
        this.getnewcontrast("nz", "en");
      } else {
        this.getnewcontrast("nz", sessionStorage.getItem("newlangusge"));
      }

      this.maricmas();
      this.$forceUpdate();
    },
    rowStyle({ row, column, rowIndex, columnIndex }) {
      if ((rowIndex % 5 == 0 || rowIndex % 7 == 0) && rowIndex != 0) {
        return "background:#fff9fa;color: #606266!important;";
      }
    },
    rangehoodone(e) {
      console.log(e);
      console.log(this.value01);
      this.january1 = e;
      this.optionsranone.forEach((el) => {
        if (el.name == this.january1) {
          console.log(el.id);
          this.theendid1 = el.id;
        }
      });
      this.value011 = "";
    },
    rangehoodtwo(e) {
      console.log(e);
      this.january2 = e;
      this.optionsrantwo.forEach((el) => {
        if (el.name == this.january2) {
          console.log(el.id);
          this.theendid2 = el.id;
        }
      });
      this.value022 = "";
    },
    rangehoodthere(e) {
      console.log(e);
      console.log(this.value03);
      this.january3 = e;
      this.optionsranthere.forEach((el) => {
        if (el.name == this.january3) {
          console.log(el.id);
          this.theendid3 = el.id;
        }
      });
      this.value033 = "";
    },
    thirsty(a) {
      this.chooseid = [];
      this.chuandata.forEach((val) => {
        // console.log(val);
        this.chooseid.push(val.id);
      });
      if (a == 1) {
        this.outgetseriesgoods("nz", "en", 1, this.theendid1);
      } else if (a == 2) {
        this.outgetseriesgoods("nz", "en", 1, this.theendid2);
      } else if (a == 3) {
        this.outgetseriesgoods("nz", "en", 1, this.theendid3);
      }
    },

    botval(e, a) {
      if (a == 1) {
        this.petandarm1 = e;
      } else if (a == 2) {
        this.petandarm2 = e;
      } else if (a == 3) {
        this.petandarm3 = e;
      }
    },
    submitconfim(a) {
      console.log(1111);

      if (this.value01 == "" || this.value011 == "") {
      } else {
        if (a == 1) {
          if (sessionStorage.getItem("newlangusge") == null) {
            this.outget_goods_info("nz", "en", this.petandarm1);
          } else {
            this.outget_goods_info(
              "nz",
              sessionStorage.getItem("newlangusge"),
              this.petandarm1
            );
          }

          this.value01 = "";
          this.value011 = "";
          this.value02 = "";
          this.value022 = "";
          this.value03 = "";
          this.value033 = "";
        } else if (a == 2) {
          if (sessionStorage.getItem("newlangusge") == null) {
            this.outget_goods_info("nz", "en", this.petandarm2);
          } else {
            this.outget_goods_info(
              "nz",
              sessionStorage.getItem("newlangusge"),
              this.petandarm2
            );
          }

          tthis.value01 = "";
          this.value011 = "";
          this.value02 = "";
          this.value022 = "";
          this.value03 = "";
          this.value033 = "";
        } else if (a == 3) {
          if (sessionStorage.getItem("newlangusge") == null) {
            this.outget_goods_info("nz", "en", this.petandarm3);
          } else {
            this.outget_goods_info(
              "nz",
              sessionStorage.getItem("newlangusge"),
              this.petandarm3
            );
          }

          this.value01 = "";
          this.value011 = "";
          this.value02 = "";
          this.value022 = "";
          this.value03 = "";
          this.value033 = "";
        }
      }
      if (this.value02 == "" || this.value022 == "") {
      } else {
        if (a == 1) {
          if (sessionStorage.getItem("newlangusge") == null) {
            this.outget_goods_info("nz", "en", this.petandarm1);
          } else {
            this.outget_goods_info(
              "nz",
              sessionStorage.getItem("newlangusge"),
              this.petandarm1
            );
          }

          this.value01 = "";
          this.value011 = "";
          this.value02 = "";
          this.value022 = "";
          this.value03 = "";
          this.value033 = "";
        } else if (a == 2) {
          if (sessionStorage.getItem("newlangusge") == null) {
            this.outget_goods_info("nz", "en", this.petandarm2);
          } else {
            this.outget_goods_info(
              "nz",
              sessionStorage.getItem("newlangusge"),
              this.petandarm2
            );
          }

          tthis.value01 = "";
          this.value011 = "";
          this.value02 = "";
          this.value022 = "";
          this.value03 = "";
          this.value033 = "";
        } else if (a == 3) {
          if (sessionStorage.getItem("newlangusge") == null) {
            this.outget_goods_info("nz", "en", this.petandarm3);
          } else {
            this.outget_goods_info(
              "nz",
              sessionStorage.getItem("newlangusge"),
              this.petandarm3
            );
          }
          this.value01 = "";
          this.value011 = "";
          this.value02 = "";
          this.value022 = "";
          this.value03 = "";
          this.value033 = "";
        }
      }

      if (this.value03 == "" || this.value033 == "") {
      } else {
        if (a == 1) {
          if (sessionStorage.getItem("newlangusge") == null) {
            this.outget_goods_info("nz", "en", this.petandarm1);
          } else {
            this.outget_goods_info(
              "nz",
              sessionStorage.getItem("newlangusge"),
              this.petandarm1
            );
          }

          this.value01 = "";
          this.value011 = "";
          this.value02 = "";
          this.value022 = "";
          this.value03 = "";
          this.value033 = "";
        } else if (a == 2) {
          if (sessionStorage.getItem("newlangusge") == null) {
            this.outget_goods_info("nz", "en", this.petandarm2);
          } else {
            this.outget_goods_info(
              "nz",
              sessionStorage.getItem("newlangusge"),
              this.petandarm2
            );
          }
          tthis.value01 = "";
          this.value011 = "";
          this.value02 = "";
          this.value022 = "";
          this.value03 = "";
          this.value033 = "";
        } else if (a == 3) {
          if (sessionStorage.getItem("newlangusge") == null) {
            this.outget_goods_info("nz", "en", this.petandarm3);
          } else {
            this.outget_goods_info(
              "nz",
              sessionStorage.getItem("newlangusge"),
              this.petandarm3
            );
          }
          this.value01 = "";
          this.value011 = "";
          this.value02 = "";
          this.value022 = "";
          this.value03 = "";
          this.value033 = "";
        }
      }

      this.$forceUpdate();
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.el-select-dropdown__item.selected {
  color: #9e2b29;
}
.constract {
  .constmiddle {
    width: 1200px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 75px;
    // border-bottom: 1px solid #e1e1e1;
    .selsectp {
      font-size: 28px;
      font-weight: 900;
      color: #231916;
      line-height: 30px;
    }
    .upmodel {
      margin-top: 20px;
      font-size: 14px;
      /* font-family: Arial; */
      font-weight: 400;
      color: #898989;
    }
    .haveimgs {
      margin-top: 45px;
      display: flex;
      .stronging {
        margin-right: 21px;
        border-radius: 8px;
        display: flex;

        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 380px;
        height: 261px;
        background: #f8f8f8;
        // display: block;
        span {
          font-size: 15px;
          font-weight: bold;
        }
        .dolling {
          width: 130px;
          height: 130px;
        }
        .maybechain {
          width: 20px;
          height: 20px;
          margin-top: 20px;
          cursor: pointer;
          opacity: 1;
        }
        .maybechain:hover {
          opacity: 0.75;
        }
      }
      .strongingoth {
        margin-right: 21px;
        border-radius: 8px;
        display: flex;
        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 380px;
        height: 261px;
        background: #f8f8f8;
        span {
          font-size: 15px;
          font-weight: bold;
        }
        .dolling {
          width: 130px;
          height: 130px;
        }
        .maybechain {
          width: 20px;
          height: 20px;
          margin-top: 20px;
          cursor: pointer;
          opacity: 1;
        }
        .maybechain:hover {
          opacity: 0.75;
        }
      }
      .serienumber {
        margin-right: 20px;

        width: 380px;
        height: 261px;
        border-radius: 8px;
        border: 1px dashed #e1e1e1;
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        .rangehd {
          margin: 26px 0px 0px 0px;
          font-size: 14px;
          font-weight: bold;
          font-size: 15px;
          text-transform: uppercase;
          font-weight: bold;
        }
        .defauk {
          margin-bottom: 20px;
          margin-top: 20px;
        }
        .likenub {
        }
        .confirmsure {
          margin-top: 20px;
          width: 240px;
          height: 40px;
          background: #000000;
          border: 1px solid #e6e6e6;
          border-radius: 6px 6px 6px 6px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;
          cursor: pointer;
        }
        .confirmsure:hover {
          background: #9d2736;
        }
        /deep/.el-select-dropdown__item.selected {
          color: #9e2b29;
        }
      }
      .serienumberoth {
        width: 380px;
        height: 261px;
        border-radius: 8px;
        border: 1px dashed #e1e1e1;
        display: flex;
        display: none;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-right: 20px;
        .rangehd {
          margin: 26px 0px 0px 0px;
          font-size: 14px;
          font-weight: bold;
          font-size: 15px;
          text-transform: uppercase;
          font-weight: bold;
        }
        .defauk {
          margin-bottom: 20px;
          margin-top: 20px;
        }
        .likenub {
        }
        .confirmsure {
          margin-top: 20px;
          width: 240px;
          height: 40px;
          background: #000000;
          border: 1px solid #e6e6e6;
          border-radius: 6px 6px 6px 6px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;
          cursor: pointer;
        }
        .confirmsure:hover {
          background: #9d2736;
        }
        /deep/.el-select-dropdown__item.selected {
          color: #9e2b29;
        }
      }
    }
    .xinaxing {
      height: 1px;
      background-color: #f2f2f2;
      margin-top: 60px;
    }
    .Specifications {
      margin-top: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .onechilds {
        font-size: 28px;
        font-weight: 900;
        color: #231916;
      }
      .doublechulds {
        width: 140px;
        height: 36px;
        border: 1px solid #93111d;
        border-radius: 6px;
        font-size: 14px;
        /* font-family: Arial; */
        font-weight: 400;
        color: #93111d;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .doublechulds:hover {
        background: #93111d;
        color: #fff;
      }
    }
    .tablelist {
      margin-top: 44px;
      margin-bottom: 200px;
      display: flex;
      justify-content: space-around;
      /deep/ .el-table .el-table__cell {
        text-align: center;
      }
      /deep/ th {
        background-color: black;
        color: white;
        height: 60px;
      }
      /deep/ tr {
        height: 60px;
        // width: 20%;
      }

      /deep/ .el-table .cell {
        // width: 100px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        // text-align: center;
      }
    }
  }
}
/deep/ .el-table--border {
  border-radius: 8px;
}
/deep/ .el-table th.el-table__cell > .cell {
  font-size: 15px;
}
// /deep/ .el-table td.el-table__cell div {
//   color: #666;
// }
/deep/ .constract .constmiddle .tablelist th {
  border: none;
  border-right: 0.0625rem solid #333333;
}
/deep/ .el-table--border .el-table__cell,
.el-table__body-wrapper
  .el-table--border.is-scrolling-left
  ~ .el-table__fixedP {
  border-right: 0.0625rem solid #f5f5f5;
}
/deep/ .el-table td.el-table__cell {
  border-bottom: 0.0625rem solid #f5f5f5;
}
/deep/ .el-table td.el-table__cell:hover {
  background: #f5f5f5;
}
/deep/ .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 0.0625rem solid #f5f5f5;
}
/deep/ .el-table th.el-table__cell.is-leaf {
  border-right: 0.0625rem solid #666;
}
/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: #eac8cd;
}
/deep/ .el-input--suffix .el-input__inner {
  width: 240px;
}
/deep/ .el-select-dropdown__item.selected {
  color: #9d2736;
}
/deep/ .el-select-dropdown__item.selected span {
  color: #9d2736;
}
/deep/ .el-select .el-input__inner:focus {
  border-color: #eac8cd;
}
/deep/ .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #f5f5f5;
}
</style>