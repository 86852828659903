<template>
  <div class="allcontainer">
    <div class="centerwidth">
      <div class="firstwird firstwirdtwo" v-if="newlist.length != 0">
        <div class="firstcontent wow animate fadeInUp delay-400ms">
          <!-- ENGINEERING COOPERATION CASES -->
        </div>
        <div class="secondcontent wow animate fadeInUp delay-500ms">
          <div class="context">
            <!-- Fotile believes that products created for love and kindness will be
            favored by the market and consumers fotile<br />
            believes that products created for love and kindness will be favored -->
          </div>
          <!-- <div class="conarrow">
           <div class="conall">All Engineering Cases</div>
           <img class="likearrow" src="../assets/img/alltitle/rowarrow.png" />
         </div> -->
        </div>
        <div class="theretext">
          <div
            class="newspaper newspapertwo wow animate fadeInUp delay-600ms"
            v-for="item in newlist"
            :key="item.id"
            @click="todetail(item.id)"
          >
            <div class="paperinner">
              <div class="">
                <!-- <img
                  class="twoimgscopy twoimgscopyTWO"
                  src="../assets/img/detailimg/g1.jpg"
                /> -->
                <img class="twoimgscopy twoimgscopyTWO" :src="item.img" />
              </div>
              <div class="lastfont">
                <!-- THE PERFECT ENCOUNTER OF CHEESE AND EGGPLANT ansd drem... -->
                {{ item.title }}
              </div>

              <div class="keoutdoor">
                <div class="learnmore learnmoretwo">
                  {{ $t("login.allting") }}
                </div>
              </div>
            </div>
          </div>

          <div class="newwidth"></div>
        </div>
      </div>
      <img
        v-if="newlist.length == 0"
        class="nodataimg"
        src="../assets/img/alltitle/nodata.png"
        alt=""
      />
    </div>
  </div>
  <!-- <div class="therelikecamera">
        <el-pagination
          background
          prev-text="prev"
          next-text="next"
          layout="prev, pager, next"
          :total="1000"
        >
        </el-pagination>
      </div>
    </div>
  </div> -->
</template>

<script>
import { WOW } from "wowjs";
import { getprojectcaselist } from "../request/api";

export default {
  data() {
    return {
      newlist: [],
      page: 1,
      limit: 99,
    };
  },
  created() {
    console.log(this.$route.query.id);
    this.newlist = this.$route.query.id;

    if (sessionStorage.getItem("newlangusge") == null) {
      this.outmenu("nz", "en", "d");
    } else {
      this.outmenu("nz", sessionStorage.getItem("newlangusge"), "d");
    }
    this.$route.meta.title = this.$t("login.querythere10");
    this.$router.replace({
      query: {
        temp: Date.now(), //必有
      },
    });
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      console.log(d);
      this.outmenu(message, b, d);
      this.$route.meta.title = this.$t("login.querythere10");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  methods: {
    outmenu(a, b, c) {
      getprojectcaselist({
        lang: b,
        country: a,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        console.log(res);
        this.newlist = res.data.data;
      });
    },
    todetail(a) {
      this.$router.push({ path: "/detailsproject", query: { id: a } });
    },
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // ��dom��Ⱦ���,��ִ�ж���
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
};
</script>
<style scoped lang='less'>
.alltitle {
  .fournavoutside {
    .navtitlehead {
      width: 100%;
      height: 70px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      .navtitleinner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 260px;
        display: flex;
        img {
          width: 20px;
          height: 20px;
        }
        .navtext {
          margin-left: 6px;
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          color: #000000;
          line-height: 24px;
        }
      }
    }
  }
  .allcontainer {
    width: 100%;
    background-color: #f2f2f2;
  }

  .centerwidth {
    width: 1200px;
    margin: 0 auto;
  }

  .firstwird {
    width: 1200px;
    display: flex;
    flex-direction: column;
    // padding-top: 70px;
    padding-bottom: 100px;
  }

  .firstcontent {
    font-size: 28px;
    /* font-family: Arial Black; */
    font-weight: 900;
    color: #000000;
  }

  .secondcontent {
    display: flex;
    /*background-color: red;*/
    justify-content: space-between;
    margin-top: 10px;
  }
  .mouthandmonkey {
    margin-top: 71px;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 1200px;
    height: 160px;
    background: #ffffff;
    border-radius: 13px 13px 13px 13px;

    .monson {
      display: flex;
      flex-direction: column;
      span {
        font-size: 14px;
        font-weight: 400;
        color: #3b3b3b;
        line-height: 30px;
      }
      .monther {
        visibility: hidden;
      }
      input {
        /* margin-top: 16px; */
        width: 280px;
        height: 40px;
        border: none;
        outline: none;
        text-indent: 15px;
        background: #f2f2f2;
        border-radius: 3px;
        font-size: 12px;
      }
      .secondtwice {
        /* width: 432px; */
      }
      .likeinpand {
        width: 187px;
        /* margin-top: 16px; */

        height: 42px;
        background: #000000;
        /* box-shadow: 5px 8px 16px 0px rgba(8, 1, 3, 0.13); */
        border-radius: 3px;
        font-size: 14px;
        /* font-family: Arial; */
        font-weight: bold;
        color: #faf8f8;
        line-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .likeinpand:hover {
        background: #9d2736;
        cursor: pointer;
      }
    }
  }

  .context {
    font-size: 15px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #000000;
    line-height: 24px;
    opacity: 0.85;
    /* width: 698px; */
  }

  .conarrow {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.75;
  }

  .conall {
    vertical-align: middle;
    font-size: 13px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #000000;
    line-height: 48px;
    cursor: pointer;
  }

  .likearrow {
    margin-left: 11px;
    vertical-align: middle;
    width: 12px;
    /* height: 14px; */
  }

  .theretext {
    display: flex;
    margin-top: 3.4375rem;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .newspaper {
    width: 386px;
    /* height: 300px; */
    background: #ffffff;
    border-radius: 7px;
    margin-bottom: 25px;
    padding-bottom: 0px;
    cursor: pointer;
    transition: transform 0.3s;
  }
  .newspaper:hover {
    transform: translate(0, -5px);
    box-shadow: 5px 8px 16px 0px rgba(8, 1, 3, 0.08);
  }

  .newspapertwo {
    width: 386px;
    /* height: 355px!important; */
    background: #ffffff;
    border-radius: 7px;
  }
  .newwidth {
    width: 386px;
  }
  .newspapertHERE {
    width: 386px;
    /* height: 335px !important; */
    background: #ffffff;
    border-radius: 7px;
  }

  .paperinner {
    margin: 18px 24px;
  }
  .paperinhelp {
    margin: 18px 24px 24px 24px;
  }
  .paperhead {
    display: flex;
    width: 315px;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 16px;
  }

  .papertime {
    font-size: 12px;
    font-family: Helvetica Neue LT Pro;
    font-weight: normal;
    color: #474545;
  }

  .paperfont {
    margin-left: 19px;
    font-size: 12px;
    font-family: Helvetica Neue LT Pro;
    font-weight: normal;
    color: #474545;
  }

  .fontbig {
    margin-top: 20px;
    /*display: flex;*/
    font-size: 18px;
    font-family: Arial;
    font-weight: bold;
    color: #3c3c3c;
    line-height: 24px;
  }

  .imgandfont {
    display: flex;
    margin-top: 20px;
  }

  .leftimgs {
    width: 82px;
    height: 66px;
    border-radius: 10px;
  }

  .rightfont {
    width: 212px;
    /* height: 60px; */
    font-size: 12px;
    line-height: 17px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #898989;
    opacity: 0.75;
    margin-left: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }

  .keoutdoor {
    display: flex;
    /* width: 315px; */
    justify-content: flex-end;
  }

  .learnmore {
    /* width: 65px; */
    margin-top: 12px;
    padding-bottom: 5px;
    font-size: 12px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #898989;
    line-height: 13px;
    /* border-bottom: 1px solid #AB1A30; */
    text-align: right;
  }

  .learnmore::after {
    content: "";
    display: block;
    background-color: #9e2b29;
    // width: 65px;
    height: 1px;
    margin-top: 4px;
    /* position: absolute; */
    font-size: 12px;
    transition: all 0.6s ease 0s;
  }

  .learnmore:hover::after {
    content: "";
    display: block;
    margin-top: 4px;
    background-color: #9e2b29;
    width: 0px;
    height: 1px;
    font-size: 12px;
    transition: all 0.6s ease 0s;
  }

  .learnmore:hover {
    color: #9e2b29;
  }

  .twoimgscopy {
    margin-top: 20px;
    width: 335px;
    /* height: 223px; */
    /* background: #000000; */
    /*opacity: 0.3;*/
    border-radius: 10px;
  }

  .twoimgscopyTWO {
    margin-top: 10px;
    width: 100%;
    height: 170px;
    background: #000000;
    /*opacity: 0.3;*/
    border-radius: 4px;
  }

  .lastfont {
    width: 100%;
    font-size: 16px;
    /* font-family: Arial; */
    font-weight: bold;
    color: #3c3c3c;
    line-height: 24px;
    margin-top: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-transform: uppercase;
  }
  .awinshangh {
    font-size: 14px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #898989;
    opacity: 0.75;
    margin: 6px 0 6px 0;
    width: 100%;
    display: block;
  }
  .lasttext {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-top: 12px;
    width: 301px;
    font-size: 12px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #898989;
    opacity: 0.75;
  }

  .learnmoretwo {
    /* margin-top: 35px; */
  }

  .firstwirdtwo {
    /* padding-bottom: 100px; */
  }
}
.modellcont {
  width: 1000px;
  min-width: 1000px;
  margin-top: 55px;
  height: 196px;
  background-color: #ffffff;
  padding: 0 100px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 120px;
  .prisun {
    .selfpro {
      margin-bottom: 8px;
      padding-left: 15px;

      font-size: 13px;
      /* font-family: Arial; */
      font-weight: 400;
      color: #757474;
      line-height: 30px;
    }
    /deep/.el-input__inner {
      background-color: #f7f7f7;
    }
  }
  .subint {
    width: 140px;
    height: 40px;
    cursor: pointer;
    background: #3a3737;
    box-shadow: 5px 8px 16px 0px rgba(8, 1, 3, 0.13);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    /* font-family: Arial; */
    font-weight: bold;
    color: #faf8f8;
    line-height: 40px;
  }
  .subint:hover {
    background: #9d2736;
  }
}
.phone,
.prisun input {
  border: 0;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  color: #000;
  font-size: 13px;
  background: #f7f7f7;
  border-radius: 8px;
  width: 300px;
  outline: none;
}
.prisun input {
  width: 210px;
}
.prisun input::placeholder {
  color: #ccc;
}
.prisun input.phone {
  width: 300px;
}
.nodataimg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  vertical-align: middle;
  width: 160px;
  height: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
}
</style>
