<template>
  <div class="bottommode">
    <div class="contacttop">
      <div class="contacttopinner">
        <div class="usselfon">
          <div class="usclass">{{ $t("login.contentus") }}</div>
          <div class="usetext">
            {{ $t("login.cookinglife") }}
          </div>
          <div class="contactsus" @click="yoning('joinin')">
            {{ $t("login.contentusnow") }}
          </div>
          <div class="phonetel">
            <img src="../assets/img/alltitle/phones.png" alt="" />
            <span>+64-9-4466 248</span>
          </div>
          <div class="phonetel phoneteltwo">
            <img src="../assets/img/alltitle/emails.png" alt="" />
            <span>info@boxsterltd.co.nz</span>
          </div>
          <div class="phonetel phoneteltwo">
            <span style="font-size: 12px; margin-left: -3px"
              >Agency company:</span
            >
            <span>BOXSTER LTD</span>
          </div>
          <div class="phonetel phoneteltwo">
            <span style="font-size: 12px; margin-left: -3px">Address:</span>
            <span>36B Hillside Road, Wairau Valley.  Auckland 0627</span>
          </div>
          <div class="phonetel phoneteltwo">
            <span style="font-size: 12px; margin-left: -3px">Wechat:</span>
            <span>boxsterltd</span>
          </div>
          <img
            style="width: 120px; height: 120px; margin-top: 5px"
            src="../assets/img/alltitle/ddddd.png"
            alt=""
          />
        </div>

        <div class="allproducts">
          <div class="selfpro">{{ $t("login.producet") }}</div>
          <div class="protext">
            <span @click.stop="addingallpro('productlist', 1)">{{
              $t("login.rangehoods")
            }}</span>
            <span @click.stop="addingallpro('productlist', 2)"
              >{{ $t("login.hobs") }}
            </span>
            <span @click.stop="addingallpro('productlist', 3)">{{
              $t("login.lectricovens")
            }}</span>
            <span @click.stop="addingallpro('productlist', 4)">{{
              $t("login.steamovens")
            }}</span>
            <span @click.stop="addingallpro('productlist', 5)">{{
              $t("login.micovens")
            }}</span>
            <span @click.stop="addingallpro('productlist', 6)">{{
              $t("login.dishwasher")
            }}</span>
          </div>
        </div>

        <div class="allproducts">
          <div class="selfpro">{{ $t("login.serives") }}</div>
          <div class="protext">
            <!-- <span>Consumer Services</span> -->
            <span @click.stop="adding('words')">{{ $t("login.faq") }}</span>
            <span @click.stop="toarht('otherwebsite', 'customer')">{{
              $t("login.mabuak")
            }}</span>
            <span @click.stop="adding('machine')">{{
              $t("login.manualdownload")
            }}</span>
            <span @click.stop="addtoexhi('exhibition')">{{
              $t("login.exhibitionhallquery")
            }}</span>
            <span @click.stop="adding('joinin')">{{
              $t("login.complaintjoin")
            }}</span>
            <!-- <span @click.stop="adding('feedback')">{{
              $t("login.complaintjoin1")
            }}</span> -->
            <!-- <span @click.stop="adding('cooperation')">{{
              $t("login.engineeing")
            }}</span> -->
          </div>
        </div>

        <div class="allproducts">
          <div class="selfpro">{{ $t("login.news") }}</div>
          <div class="protext">
            <span @click.stop="adding('alltitle', 2)">{{
              $t("login.fotilenews")
            }}</span>
            <span @click.stop="adding('alltitle', 3)">{{
              $t("login.fotilemedia")
            }}</span>
            <span @click.stop="adding('alltitle', 4)">{{
              $t("login.creativemenu")
            }}</span>
          </div>
        </div>

        <div class="allproducts allproductstwo">
          <div class="selfpro">{{ $t("login.about") }}</div>
          <div class="protext">
            <span @click.stop="adding('about')">{{
              $t("login.aboutfotial")
            }}</span>
            <span @click.stop="adding('informationcopy')">{{
              $t("login.happykitchen")
            }}</span>
            <!-- <span>Fotile Strength </span> -->
            <!-- <span @click.stop="adding('wholeworld')">{{
              $t("login.globalout")
            }}</span> -->
          </div>
        </div>

        <!-- <div class="allproducts allproductsthere">
          <div class="selfpro">{{ $t("login.follow") }}</div>
          <div class="protext">
            <span @click="TOAHH1">Facebook</span>
            <span @click="TOAHH2">Ins</span>
            <span @click="TOAHH3">YouTube</span>
          </div>
        </div> -->
      </div>
      <div class="fotaillogo">
        <div class="ftileft">
          <div class="pearselfti">
            <img
              class="balcklogo"
              src="../assets/img/alltitle/balck_logo.png"
              alt=""
            />
          </div>
          <div class="overseass">
            {{ $t("login.allright") }}
          </div>
          <!-- <div class="meiti">
            <img src="../assets/img/alltitle/dy.png" />
            <img src="../assets/img/alltitle/tt.png" />
            <img src="../assets/img/alltitle/facebook.png" />
            <img src="../assets/img/alltitle/whatsapp.png" />
            <img src="../assets/img/alltitle/youtube.png" />
          </div> -->
        </div>
        <!-- <div class="ftiright">
          <div>{{ $t("login.sitemap") }}</div>
          <div>{{ $t("login.legalnotice") }}</div>
          <div>{{ $t("login.globalsite") }}</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    yoning(url) {
      console.log(url);
      this.$router.push({
        path: url,
      });
    },
    toarht(url, a) {
      this.$router.push({
        path: url,
        query: { type: 3, name: a, cate: 0 },
      });
      location.reload();
    },
    TOAHH1() {
      var url = "https://www.facebook.com/fotileau";
      window.open(url, "_blank");
    },
    TOAHH2() {
      var url = "https://www.instagram.com/fotileaus/";
      window.open(url, "_blank");
    },
    TOAHH3() {
      var url =
        "https://www.youtube.com/c/FotileAustralia%E6%96%B9%E5%A4%AA%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A";
      window.open(url, "_blank");
    },
    adding(url, b) {
      // this.$router.push(url);
      this.$router.push({
        path: url,
        query: { newnumbertype: b },
      });
      document.documentElement.scrollTop = 0;
    },
    addingallpro(url, b) {
      this.$bus.$emit("add", "nz", "en", b);

      this.$router.push({
        path: url,
        query: { id: b },
      });
      // location.reload();
      this.$bus.$emit("chaneshuing", "nz", "en", b);
      document.documentElement.scrollTop = 0;
    },
    addtoexhi(url) {
      console.log(111);
      this.$router.push({
        path: url,
      });
    },
  },
};
</script>
<style lang='less' scoped>
* {
  font-family: "PingFang SC", "PingHei", "STHeitiSC-Light", "Myriad Set Pro",
    "Lucida Grande", "Helvetica Neue", "Helvetica", "microsoft yahei", "SimHei",
    "tahoma", "Arial", "Verdana", "sans-serif";
  *font-family: "SimHei";
  transition: all 0.3s linear;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 0px;
  background: rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #999;
}
::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background: #999;
}

.bottommode {
  /* border-top: 1px solid #f2f2f2; */
  background: #fafafa;
  // width: 100%;

  // position: fixed;
  // bottom: 0px;
  // left: 0px;
  .contacttop {
    width: 100%;
    z-index: 9;
    // height: 415px;
    position: relative;
    bottom: 0px;
    left: 0px;
    padding-top: 5px;
    /* background-color: white; */
    .contacttopinner {
      display: flex;
      margin: 21px 126px 24px 126px;
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 20px;
      .usselfon {
        display: flex;
        flex-direction: column;
        width: 40%;
        .usclass {
          border-bottom: 1px solid #f2f2f2;
          padding-bottom: 20px;
          padding-top: 25px;
          font-size: 14px;
          /* font-family: Arial; */
          font-weight: bold;
          color: #0e0d0b;
          opacity: 0.75;
          text-transform: uppercase;
        }
        .usetext {
          margin-top: 26px;
          font-size: 12px;
          /* font-family: Arial; */
          font-weight: 400;
          color: #000000;
          line-height: 20px;
          opacity: 0.6;
          width: 75%;
        }
        .contactsus {
          width: 171px;
          height: 42px;
          background: #0a0a0a;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          margin-top: 20px;
          font-size: 12px;
          /* font-family: Arial; */
          /* font-weight: bold; */
          color: #ffffff;
          letter-spacing: 1px;
          border-radius: 3px;
          cursor: pointer;
          /* opacity: 0.75; */
        }
        .contactsus:hover {
          background: #8d201e;
        }
        .phonetel {
          display: flex;
          margin-top: 20px;
          display: flex;
          // justify-content: center;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
          }
          span {
            margin-left: 10px;
            font-size: 12px;
            /* font-family: Arial; */
            font-weight: 400;
            color: #000000;
            line-height: 28px;
            opacity: 0.6;
          }
        }
        .phoneteltwo {
          margin-top: 10px;
        }
      }
      .allproducts {
        display: flex;
        flex-direction: column;
        width: 15%;
        .selfpro {
          border-bottom: 1px solid #f2f2f2;
          padding-bottom: 20px;
          padding-top: 25px;
          text-transform: uppercase;
          font-size: 14px;
          /* font-family: Arial; */
          font-weight: bold;
          color: #0e0d0b;
          opacity: 0.75;
        }
        .protext {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          font-size: 12px;
          /* font-family: Arial; */
          font-weight: 400;
          color: #000000;
          line-height: 28px;

          span {
            margin-bottom: 5px;
            cursor: pointer;
            opacity: 0.6;
            transition: all 0.3s linear;
          }
          span:hover {
            color: #9e2b29;
            opacity: 1;
          }
        }
      }
      .allproductstwo {
        width: 20%;
      }
      .allproductsthere {
        width: 10%;
      }
    }
    .fotaillogo {
      margin: 0px 126px;
      margin-top: 0px;
      padding-bottom: 20px;
      /* margin-bottom: 20px; */
      display: flex;
      justify-content: space-between;
      .ftileft {
        display: flex;
        .pearselfti {
          font-size: 14px;
          /* font-family: Arial Black; */
          font-weight: 400;
          color: #000000;
          img {
            width: 86%;
          }
        }
        .overseass {
          margin-left: 12px;

          font-size: 12px;
          /* font-family: AR HeiGB; */
          /* font-weight: 500; */
          color: #898989;
          line-height: 18px;
          /* opacity: 0.75; */
        }
        .meiti {
          margin-left: 12px;
          img {
            width: 16px;
            /* height: 14px; */
            margin-left: 12px;
            opacity: 0.75;
          }
        }
      }
      .ftiright {
        display: flex;
        div {
          border-right: 1px solid #e1e1e1;
          cursor: pointer;
          font-size: 12px;
          /* font-family: AR HeiGB; */
          font-weight: 500;
          color: #898989;
          line-height: 18px;
          /* opacity: 0.75; */
          padding-right: 10px;
          padding-left: 10px;
        }
        div:hover {
          color: #9e2b29;
        }
        div:last-child {
          border-right: none;
        }
      }
    }
  }
}
</style>