module.exports = {	
	language: {
		name: 'English'
	},
	
	login: {
		title:"中文",
		home: 'Home',
		producet: 'Products',
		producets: 'Buy now',
		serives: 'Services',
		news: 'News',
		about: 'About',
		follow: 'Follow',
		rangehoods: 'Range Hoods',
		hobs: 'Hobs',
		lectricovens: 'Electric Ovens',
		steamovens: 'Steam Ovens',
		micovens: 'Microwave Ovens',
		dishwasher: 'In-Sink Dishwasher',
		allproduct:"All Products",
		faq: 'FAQ',
		manualdownload: 'Download',
		exhibitionhallquery: 'Store Inquiry',
		complaintjoin: 'Contact Fotile',
		complaintjoin1: 'Complaints',
		engineeing: 'Engineering Cooperation',
		aboutfotial: 'About FOTILE',
		happykitchen: 'Happy Kitchen',
		globalout: 'Global Distribution',
		fotilenews : 'FOTILE News',
		fotilemedia: 'FOTILE Video',
		creativemenu : 'Recipes for Creativity',
		contentus : 'Contact Us',
		contentusnow : 'Contact us now',
		cookinglife: 'The most advanced technology guides you to a revolutionary cooking lifestyle through happy cooking',
		allright : 'FOTILE Overseas Kitchen Appliance Co., Ltd. All rights reserved',
		sitemap : 'Site Map',
		legalnotice : 'Legal Notice',
		globalsite: 'Global Site',
		biginning : 'FOTILE is always focused on the field of kitchen appliances',
		nowhave : 'In New zealand, now we have six product lines: range hoods, built-in hobs, built-in microwave ovens, built-in ovens, built-in steam ovens, and sink dishwashers. ',
		happytochoose : 'Happiness Starts In Your Kitchen',
		allowaystodo : 'FOTILE has always been committed to providing people with high-quality products and services, creating a healthy and environmentally-friendly lifestyle, and allowing more families to enjoy a happy and confident kitchen life. For the last 20 years, we have helped families around the world improve their kitchen environments and protect their health. We are forging ahead for the happiness of hundreds of millions of families.',
		moreandmore: 'More',
		number1: 'FOTILE insists on investing no less than 5% of sales revenue in R&D every year and has a team of more than 900 people, including experts in the field of kitchen appliances.',
		number2: 'FOTILE currently has more than 16,000 employees across the country. In addition to strong local design capabilities, FOTILE also has design facilities in South Korea, Japan, and other places, as well as high-end kitchen production equipment and advanced international industrial manufacturing technology.',
		number3: 'Rated in the first class of national intellectual property showcase enterprises by the State Intellectual Property Office in 2013, FOTILE has accumulated dozens of core technologies for kitchen appliances. It has more than 4,500* authorized patents, including more than 700* invention patents, making it a leader in the industry.',
		number4: 'FOTILE has a nationally recognized business technology center and a laboratory of more than 12,000 square meters recognized by the China National Accreditation Service for Conformity Assessment. It has also established research institutes in Germany, Japan, and other places.',
		newactive: 'News Activities',
		newmodel: 'FOTILE implements a modern Confucian management model, actively participating in the promotion of traditional culture, charity, environmental protection, and other public welfare undertakings, and strives to practice corporate social citizenship.',
		newnext: 'Learn More About FOTILE',
		newsersh: 'Dedicated Customer Service',
		newkonw: 'Want to learn more? We will arrange for our dedicated customer service to contact you as soon as possible',
		xielipro: 'Series Products',
		xingnum: 'Product Models',
		conttype: 'Telephone number',
		submitsing: 'Submit',
		ochoosepro: 'Select Product',
		ochoosexinghoa: 'Select Model',
		contrast: 'Compare',
		dingyue: 'Subscribe',
		baoxiu: 'Warranty Card',
		protobi: 'Product Comparison',
		obempty: 'Empty',
		osure: 'Confirm',
		mabymost: 'Compare up to three types',
		guige: 'Specifications',
		qubie: 'Differences',
		shipinvideo: 'Video',
		dayijin: 'Printer',
		masgomai: 'Buy Now',
		tobuy: 'Buy',
		features: 'Features',
		prodetail: 'Product Details',
		teparnters: 'Technical Parameters',
		installation: 'Installation Diagram',
		manual: 'Product Manual',
		tuijianpro: 'Recommended Product',
		mandetai1: 'Manual',
		mandetai2: 'Technical Parameters',
		mandetai3: 'Installation Guide',
		mandetai4: 'Warranty Policy',
		mandetai5: 'Product Registration',
		mandetai6: 'Brochure',
		cannotdoit: 'Currently Unavailable',
		downloadli: 'Download',
		serisepro: 'FOTILE provides you with answers to frequently asked questions during the use of each product series',
		pleachoose: 'Please choose',
		ochaxue: 'Query',
		allwholeworld: 'Global Distribution',
		wholedetail:'FOTILE is advancing step-by-step on the road to becoming the world is top kitchen appliance expert and has launched distribution in more than 30 countries in North America, Europe, Australia, Asia, and Africa',
		tojoinwant: 'I have comment',
		tothankyou: 'Thank you for contacting us, we will do our absolute best for your satisfaction!',
		inputname1: 'Company Name',
		inputname2: 'If you have any questions, you can leave a message or call, and you can also choose our online customer service chat',
		inputname3: 'Company Address',
		inputname4: 'NAME',
		inputname5: 'Mobile Phone',
		inputname6: 'Email',
		inputname7: 'message',
		inputname8: 'Main Products',
		casesone: 'Learn About FOTILE Engineering',
		casesoneson: 'Since 2005, FOTILE has had the foresight to establish a professional engineering marketing team to enter the real estate fine decor field. While cooperating with many real estate companies and constantly exploring customer needs with an eye to self-improvement, it has become a kitchen appliance brand that has many strategic customers in the fine decor market and has acquired many kitchen appliance brands for fine decor projects. To date, FOTILE has cooperated with thousands of real estate companies in more than 200 large, medium, and small cities across the country, and has successfully delivered nearly 10,000 fine decor projects, forming strategic alliances with more than 160 well-known real estate companies across the country. These impressive results are only the beginning!',
		casestwo: 'Example of Typical Project',
		casestwoson: 'To date, FOTILE has cooperated with thousands of real estate companies in nearly two hundred large, medium, and small cities across the country, delivered nearly 10,000 fine decor projects, and formed strategic alliances with more than 160 well-known real estate companies across the country. These impressive achievements are only the beginning!',
		casesthere: 'Kitchen Appliance Project Collaboration',
		casesthereson: 'If you would like to collaborate with us, please fill in the contact information. We will contact you as soon as possible.',
		oinputname1: 'Title:',
		oinputname2: 'Company Information:',
		oinputname3: 'Language:',
		oinputname4: 'Contact Phone Number:',
		oinputname5: 'Address:',
		Engineering: 'Get to know FOTILE',
		calssing: 'Example of Engineering',
		parkservise: 'Engineering Worry-free Service',
		shouafter: 'Engineering Service Process',
		gorgeous: 'Our own professional engineering project service team',
		authority: 'From project investigation and product installation to delivery to the owner, FOTILE is professional engineering project service personnel are there every step of the way, with strong on-site coordination and standardized service execution capabilities.',
		lens: 'Professional project service management tracking',
		erosion: 'Project management, batch construction',
		fatal: 'Efficient project management model',
		motive: 'From construction environment survey, delivery coordination, and construction quality control to on-site receiving, we have a project management system that meets customer needs throughout the process.',
		cicumstance: 'Project responsibility system',
		embody: 'A scientific and efficient project management system',
		mutiply: 'Seamless integration with the supply chain',
		wresetle: 'Our own service team has direct access to the national supply chain system to ensure excellent product quality and timely delivery of goods.',
		pasture: 'Strong delivery guarantee',
		approval: 'Ensuring timely delivery',
		handing: 'Professional engineering service site management standards',
		morning: 'With an industry-leading quality control system, material standards, and on-site management standards, and the efficient execution of our own service team, we have the ability to provide excellent, worry-free engineering services.',
		handsed: 'Environmental Standards',
		hairsing: 'Construction Standards',
		ducking: 'Material Tool Standards',
		bite: 'Process Standards',
		rabbit: 'Fast and efficient on-site feedback mechanisms',
		cakeing: 'We have a project management responsibility system, and the whole process of project management is coordinated to ensure that the on-site feedback is fast and efficient.',
		badanmad: 'Project Management System',
		smalling: 'Full responsibility for the whole process',
		trutle: 'Creating a "3C Worry-Free Project" service process',
		weeksing: 'Using FOTILE is smart, caring, and comprehensive professional services allows our partners to gain greater space for value and enjoy a perfect collaborative experience.',
		jscket: 'Personal Support',
		shirting: 'Confident Installation',
		sweater: 'Satisfaction Guaranteed',
		hurtsing: 'Satisfaction Guaranteed',
		potato: 'Any consultation, repair application, complaint, or suggestion after the owner moves in can be handled through the call center, with no worries for the developer.',
		tomato: 'The maintenance period for our nine series of products (range hoods, stoves, sterilizers, integrated steamer-microwaves, steamers, ovens, microwaves, dishwashers, water heaters) is five years.',
		Presales: 'Presale Service',
		Aftersale: 'Aftersale Service',
		Onsale: 'Sales',
		Presales1: 'Project Investigation',
		Presales2: 'Environmental Correction',
		Presales3: 'Selection Plan',
		Presales4: 'Embedded Exhaust Duct',
		Presales11: 'Professional technical service engineers conduct multiple environmental surveys before and after the project is established, promptly discovering environmental problems that may affect the owner is experience.',
		Presales12: 'Matching survey results with customer needs, we provide customers with cost-effective environmental correction solutions and continue to carry out free environmental surveys and service support during the correction process.',
		Presales13: 'According to customer needs and project conditions, we provide customers with a variety of kitchen solutions, and timely and professional technical support.',
		Presales14: 'We provide free duct pre-embedding services according to the installation progress to guarantee the owner is experience.',
		Onsale1: 'Construction organization plan',
		Onsale2: 'On-site collaboration',
		Onsale3: 'Factory-direct supply',
		Onsale4: 'Delivery coordination',
		Onsale5: 'Marketing support',
		Onsale11: 'We complete a professional construction organization plan before construction, effectively avoiding problems that may be encountered in the construction process and improving the efficiency of on-site coordination.',
		Onsale12: 'Professional project service engineers coordinate on-site construction throughout the entire process and cooperate with on-site work to ensure construction quality.',
		Onsale13: 'Goods supply mechanisms from the factory direct to the project site ensure product quality and effectively reduce the loss of goods caused by logistics for timely delivery',
		Onsale14: 'We coordinate with the owner during the whole process, providing necessary product instructions and service support to improve the customer is satisfaction.',
		Onsale15: 'Integration with sales needs provides owners with high-quality construction process standards, product use and service commitment explanations, and other service support.',
		Aftersale1: 'All-weather service with sincere care',
		Aftersale2: 'Five-year warranty',
		Aftersale11: 'Our service with sincere care integrates the essence of Confucian culture and makes honesty our core value. We are committed to providing customers with a service experience that is timely, professional, and attentive, so thousands of families feel better at home.',
		Aftersale12: 'We repair our range hoods, stoves, sterilizers, integrated steamer-microwaves, steamers, ovens, microwaves, dishwashers, and water heaters for 5 years, so the owner can use them with greater peace of mind.',
		
		allting: 'More',
		informationing: 'Information & Activities',
		squareimage: 'Fotile video',
		gourmet: 'Creative Recipes',
		fantings: 'For the last 20 years, we have helped families around the world improve their kitchen environments and protect their health. We are forging ahead for the happiness of hundreds of millions of families.',
		cokintmast: 'FOTILE is committed to providing you with a comfortable and safe cooking space, and protecting the health of your family.',
		mostdetail:'Details',
		about1:'The happy choice for global kitchens',
		about11:'FOTILE has always been committed to providing people with high-quality products and services, creating a healthy and environmentally-friendly lifestyle, and allowing more families to enjoy a happy and confident kitchen life. For the last 20 years, we have helped families around the world improve their kitchen environments and protect their health. We are forging ahead for the happiness of hundreds of millions of families.',
		about2:'FOTILE R&D and Innovation',
		about22:'As a leader in the field of kitchen appliances, FOTILE has top R&D and design expertise from around the world, high-end kitchen production equipment, advanced international industrial manufacturing technology, and R&D centers in Germany, Japan and China. As of March 2021, FOTILE has more than 4,500 authorized patents, including more than 700 invention patents, making it an industry leader. Our scientific research excellence ensures the innovative strength of FOTILE. Since 2007, FOTILE has won 28 iF awards and 22 reddot awards, as well as other international awards, becoming an industry leader.',
		about3:'Healthy Kitchen Life',
		about33:'FOTILE cares about the health of every user. We firmly believe that the kitchen is not just a place to make three meals a day, but also the space where a family explores the joy of cooking and sharing a happy life full of love. Each meal is not just a daily necessity,but also an expression of each family is attitude towards life. FOTILE is committed to providing you with a comfortable and safe cooking space, and protecting the health of your family.',
		about4:'Industry Leading Brand',
		about44:'FOTILE focuses on R&D and the production of high-end kitchen appliances, with a complete line of kitchen appliances, including range hoods, stoves, steamers, ovens, sink dishwashers, etc., and is the preferred kitchen appliance brand for more than 17 million households in more than 30 countries around the world. As of 2020, Fotile has been ranked among the top 500 Asian brands for seven consecutive years with its unique innovative model and profound brand value, ranking first in the kitchen appliance industry.',
		
		registering:'Customer Information',
		nottoregis:'FOTILE will not disclose your personal information to another party',
		kehunews1:'Surname',
		kehunews2:'First name',
		kehunews3:'Phone number',
		kehunews4:'Email',
		kehunews5:'Address',
		kehunews6:'Apartment/suite/etc.',
		kehunews7:'City/town',
		kehunews8:'Status',
		kehunews9:'Postal Code',
		kehunews10:'Select category',
		kehunews11:'Select model',
		kehunews12:'Product serial number',
		kehunews13:'Where did you purchase FOTILE?',
		kehunews14:'Direct selection or search selection',
		kehunews15:'Purchase Date',
		kehunews16: 'Reseller/Amazon Invoice/Order Number',
		nowtoregister:'Register Now',
		personal:'Personal Information',
		toubuy:'Purchase Products',
		halsthy:'Healthy Kitchen Life',
		alwaysanli:'FOTILE has always been committed to providing people with high-quality products and services, creating a healthy and environmentally-friendly lifestyle, and allowing more families to enjoy a happy life with peace of mind in the kitchen',
		joninene:'Subscribe to the FOTILE newsletter',
		ionudd:'Join our mailing list to receive new product reminders, special offers, and discount codes',
		
		joininn: 'Join',
		seriesing:'Series Introduction',
		
		food1:"Low-Calorie Staples | Bacon and Avocado Sandwich",
		food2:'Low-Calorie Breakfast | Leek Omelette',
		food3:'Low-Calorie Fruit & Vegetable Juice | Broccoli & Pear Juice',
		food4:'Late Night Snack | Very Low-Calorie Harusame Salad',
		food5:'Creative Cuisine | Rice Balls with Crown Daisy, Potatoes and Brown Rice',
		
		newadd1:'top products',
		newadd2:'Best Product Recommendation of the Month',
		
		feedback1: 'name',
		feedback2: 'country',
		feedback3: 'city',
		feedback4: 'phone',
		feedback5: 'describe',
		wantfaku:'I have comment',
		wantfakuson:'Your help and support is highly appreciated!',
		tofaku:'comment',
		

shiname:'FOTILE IS A MISSION, VISION AND VALUE-DRIVEN ENTERPRISE.',
		hospion:'CHAIRMAN AND PRESIDENT : ',


borderred:'Please fill in this field',
sucssfil:'Submitted successfully',



mabuak:'Consumerservices',
bighand: 'All manuals',






storeing: 'store',
		onlneing: 'online',
		fangdi:'real estate',


newthis:'Steam Oven Menu',





querythere1:'FOTILE',
querythere2:'Productlist',
querythere3:'Contrast',
querythere4:'Product details',
querythere6:'Download Manuals',
querythere7:'Store Inquiry',
querythere8:'Contact Fotile',
querythere9:'Complaints',
querythere10:'Engineering Cooperation',
querythere11:'FOTILE News',
querythere12:'FOTILE Video',
querythere13:'Recipes for Creativity',
querythere14:'Engineering Cooperation Detail',
querythere15:'FOTILE News Detail',
querythere16:'FOTILE Video Detail',
querythere17:'Recipes for Creativity Detail',
querythere18:'About FOTILE',
querythere19:'Happy Kitchen',
querythere20:'Warranty Card',


cancel:'cancel',






	}
}
