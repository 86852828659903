module.exports = {
	
	language: {
	    name: 'other'
	},
		
	login: {
		title:"中文",
		home: '홈',
		producet: '상품',
		producets: '쇼핑 카트',
		serives: '고객지원',
		news: '뉴스',
		about: '소개',
		follow: '문의하기',
		rangehoods: '후드',
		hobs: '쿡탑',
		lectricovens: '오븐',
		steamovens: '스팀오븐',
		micovens: '전자레인지',
		dishwasher: '씽크식기세척기',
		allproduct:"전체상품",
		faq: 'FAQ',
		manualdownload: '매뉴얼 다운로드',
		exhibitionhallquery: '매장 통합검색',
		complaintjoin: '연락처 fotile',
		complaintjoin1: '고소와',
		engineeing: '협력 프로젝트',
		aboutfotial: '회사소개',
		happykitchen: '행복한 주방',
		globalout: '지구분포',
		fotilenews : 'FOTILE뉴스',
		fotilemedia: 'FOTILE 미디어',
		creativemenu : '창의적인 레시피',
		contentus : '연락처',
		contentusnow : '긴급 연락처',
		cookinglife: '가장 진보화된 기술로 당신에게 즐거운 요리와 혁신적인 라이프스타일 경험을 선사합니다.',
		allright : 'FOTILE해외주방가전주식회사의 판권소유',
		sitemap : 'site map',
		legalnotice : '법률적 해석',
		globalsite: '글로벌 사이트',
		biginning : 'FOTILE그룹은 늘 주방가전 영역에 전념하고 있습니다.',
		nowhave : '후드, 빌트인쿡탑, 빌트인전자렌지, 빌트인오븐, 빌트인스팀오븐, 세척기등 6개의 제품라인을 갖추고 있습니다. ',
		happytochoose : '글로벌주방의 행복한 선택 ',
		allowaystodo : 'FOTILE은 그동안 고품질의 제품과 서비스를 제공하여 보다 많은 가정이 행복하고 편안한 주방생활을 즐길 수 있도록 건강하고 친환경적인 라이프스타일을 만들어왔습니다.  지난 20여년동안 우리는 세계각지의 가정의 주방환경을 개선하고 주방의 건강함을 지켜왔습니다.  1조이상 가정의 행복을 위해 계속 연마하여 나아가겠습니다. ',
		moreandmore: '더 알아보기',
		number1: 'FOTILE의 매출액의 5% 를 연구개발에 투입하는 것을 고집하고 있으며, 주방가전 분야의 전문가를 포함하여 900여명의 연구개발 인력을 보유하고 있습니다. ',
		number2: 'FOTILE은 현재 중국내 16000여명의 임직원이 있으며, 풍부한 현지설계 실력뿐만 아니라 한국과 일본의 설계역량과 고급주방생산설비와 국제 공업제조의 선진 기술을 보유하고 있습니다. ',
		number3: '2013년 중국 국가지식재산권청으로부터 국가급 지식재산권 시범기업으로 선정돼 수십 건의 주방가전제품의 핵심 기술을 축적하였습니다. 7200*여 건의 라이센스 특허를 보유하고 있으며, 이 중 발명 특허가 700*건을 넘는 업계에서 손꼽히는 브랜드입니다.',
		number4: 'FOTILE은 국가가 인정하는 기업기술센터와 중국합격평정국가인가위원회가 승인한 1만2000평이 넘는 실험실을 갖고 있으며 독일·일본 등지에도 연구원을 설립하였습니다.',
		newactive: '정보활동',
		newmodel: 'FOTILE 기업은 현대적 유교관리모델을 추진하고 동시에 전통문화 보급, 자선, 친환경 등 공익사업을 적극 지원하며, 기업-사회-시민 연대 책임을 실천하기 위하여 노력하고 있습니다.  ',
		newnext: 'FOTILE에 대하여 깊이 이해하기',
		newsersh: '전용 고객상담',
		newkonw: '더 많은 정보를 원하신다면 빠른 시일내에 전용 고객서비스를 마련하여 연락드리겠습니다.',
		xielipro: '시리즈 제품',
		xingnum: '모델명',
		conttype: '연락처',
		submitsing: '제출',
		ochoosepro: '제품 선택',
		ochoosexinghoa: '모델명 선택',
		contrast: '비교하기',
		dingyue: '신청',
		baoxiu: '보증 카드',
		protobi: '상품 비교하기',
		obempty: '비우기',
		osure: '확인',
		mabymost: '최대 3가지 유형비교',
		guige: '스펙',
		qubie: '구별',
		shipinvideo: '동영상',
		dayijin: '프린터',
		masgomai: '바로 구매',
		tobuy: '구매',
		features: '특정',
		prodetail: '제품상세정보',
		teparnters: '기술 제원',
		installation: '설치장면',
		manual: '제품 브로셔',
		tuijianpro: '추천제품',
		mandetai1: '브로셔',
		mandetai2: '기술 제원',
		mandetai3: '설치지침서',
		mandetai4: '보증정책',
		mandetai5: '제품등록',
		mandetai6: '카탈로그',
		cannotdoit: '대기중',
		downloadli: '다운',
		serisepro: 'FOTILE이 각 제품별 사용중 자주 묻는 질문에 대하여 답하여 드립니다.',
		pleachoose: '선택하여 주세요',
		ochaxue: '문의',
		allwholeworld: '전 세계 분포',
		wholedetail: 'FOTILE은 세계최고의 주방가전 전문가의 길을 한걸음 씩 나아가 북미, 유럽, 호주, 아시아, 아프리카 시장에 분포하는 30여 국가에 진입하였습니다. ',
		tojoinwant: 'FOTILE에 가입하고 싶습니다. ',
		tothankyou: '저희에게 연락하여 주셔서 감사합니다. 당신의 만족을 위하여 100% 노력하겠습니다. ',
		inputname1: '회사명칭',
		inputname2: '출범시기',
		inputname3: '회사주소',
		inputname4: '이름',
		inputname5: '핸드폰',
		inputname6: 'email',
		inputname7: '메세지',
		inputname8: '주력제품',
		casesone: 'FOTILE 공사 프로젝트',
		casesoneson: 'FOTILE은 2005년 부터 전문 엔지니어링 마케팅팀을 구성하여 건설사 프로젝트 영업을 시작하였습니다. 많은 건설사와 협력하면서 고객의 수요를 끊임없이 개선하였고 스스로의 보완과 성장을 통하여 수많은 건설사 고객을 확보하고 수많은 건설사 프로젝트를 진행하는 최고의 주방가전 브랜드가 되었습니다. 지금까지 FOTILE은 200여개의 도시에서 수 천개 건설사의 만 건 이상의 프로젝트를 완벽히 수주 납품하였고 160여개 국가의 유명한 건설사와 전략적 제휴를 맺는 등 꾸준히 성과를 이끌어 내고 있습니다.  ',
		casestwo: '프로젝트 대표 사례 ',
		casestwoson: '지금까지 FOTILE은 200여개의 도시에서 수 천개 건설사의 만 건 이상의 프로젝트를 완벽히 수주 납품하였고 160여개 국가의 유명한 건설사와 전략적 제휴를 맺는 등 꾸준히 성과를 이끌어 내고 있습니다.  ',
		casesthere: '가전 비즈니스 협력',
		casesthereson: '협력의사가 있으시면 연락정보를 기입하여 주시면 빠른 시일내에 연락드리겠습니다. ',
		oinputname1: '호칭:',
		oinputname2: '회사정보:',
		oinputname3: '언어:',
		oinputname4: '연락처:',
		oinputname5: '이메일:',
		Engineering: 'FOTILE 인식:',
		calssing: '프로젝트 사례',
		parkservise: '프로젝트 보증서비스',
		shouafter: '프로젝트 서비스 프로세스',
		gorgeous: '우리에게는 전문 엔지니어링 프로젝트 서비스팀이 있습니다.',
		authority: '프로젝트 현장실측부터 제품설치와 입주자에게 제품을 인도하기까지 모든 서비스는 FOTILE 전문 프로젝트 엔지니어링 서비스인원의 책임관리하에 표준화 서비스를 제공합니다. ',
		lens: '전문 프로젝트 서비스 매니저의 추적관리',
		erosion: '대규모 프로젝트 설치 관리',
		fatal: '효율적인 프로젝트 관리 모델',
		motive: '시공환경 실측, 제품도착, 시공품질관리, 현장환경 협력관리까지 고객의 요구에 만족시킬 수 있도록 프로젝트 전 과정을 관리하는 시스템',
		cicumstance: '프로젝트 책임제',
		embody: '과학적이고 효율적인 프로젝트 관리 시스템',
		mutiply: 'SCM의 빈틈없는 협력관리',
		wresetle: '자체 서비스팀이 전국적 SCM 관리로 우수한 품질과 제품 적기도착을 보장합니다. ',
		pasture: '강력한 입주자점검 서비스',
		approval: '제품 적기도착 보장',
		handing: '전문적인 엔지니어링 서비스 현장 관리 표준',
		morning: '업계 내의 엄격한 품질관리체계, 자재표준과 현장 관리표준을 갖추고 있으며 자체 서비스팀의 효율적인 실행력에 맞춰 양호하고 우수한 프로젝트 엔지니어링 서비스를 구축하였습니다. ',
		handsed: '환경표준',
		hairsing: '시공표준',
		ducking: '표준 재료공구',
		bite: '표준 프로세스',
		rabbit: '신속하고 효율적인 피드백 시스템',
		cakeing: '프로젝트 매니저 책임제, 전 과정 프로젝트 전담관리로 빠른 현장 피드백을 보장합니다.',
		badanmad: '프로젝트 매니저 체계',
		smalling: '전 과정 전담관리',
		trutle: '3C걱정 없는 프로젝트 서비스 프로세스',
		weeksing: 'FOTILE의 민첩하고 진심 어린 포괄적인 전문 서비스로 우리의 파트너에게 더 큰 가치를 돌려드릴 수 있도록 완벽한 협력을 약속합니다. ',
		jscket: '맞춤형 선택',
		shirting: '전심전력 시공',
		sweater: '만족보장',
		hurtsing: '만족보증',
		potato: '입주후 어떠한 문의, 점검, 민원 및 건의도 콜센터를 통하여 처리할 수 있어 건설사의 근심을 덜어드리겠습니다. ',
		tomato: '9가지 제품시리즈 (후드, 쿡탑, 세척기, 스팀오븐/전자레인지, 스팀오븐, 광파오븐, 전자레인지, 식기세척기, 온수기) 의 수리기간은 모두 5년입니다. ???',
		Presales: '판매전',
		Aftersale: '판매후',
		Onsale: '판매중',
		Presales1: '프로젝트 현장실측',
		Presales2: '환경 교정',
		Presales3: '선택형 방안',
		Presales4: '연통배관 사전매립',
		Presales11: '전문 서비스 기술자가 프로젝트 공사 전후단계 여러 차례 현장을 방문하고 실측관리하여  입주자의 사용편의에 영향을 줄 수 있는 문제점 등을 처리합니다. ',
		Presales12: '현장 실측 조사와 고객의 요구를 결합하여 고객에게 경제적이고 효과적인 개선 방안을 제공하겠습니다. ',
		Presales13: '고객의 요구 및 프로젝트 상황에 따라 다양한 주방 솔루션을 제공하고 적시에 전문적인 기술협력을 지원할 수 있습니다.',
		Presales14: '현장 진도에 따라 연통배관을 사전에 매립하여 입주자의 편리한 사용을 지원합니다.',
		Onsale1: '시공조직방안',
		Onsale2: '현장 협력',
		Onsale3: '공장직접공급',
		Onsale4: '입주자점검 협력',
		Onsale5: '마케팅 지원',
		Onsale11: '시공투입전 전문적인 시공조직을 완성하여 시공과정중 발생할 수 있는 문제를 효과적으로 사전 파악하여 현장내 협력 효율을 높입니다.',
		Onsale12: '전문적인 프로젝트 매니저가 전 과정을 관리 감독하여 현장 작업진도에 맞추어 최고의 시공품질을 확보합니다. ',
		Onsale13: '공장창고에서 프로젝트 현장으로 직접 배송하는 화물공급 시스템으로 제품의 품질확보와 물류로 인한 제품의 손실을 최소하하고 제품이 적시에 현장에 도착할 수 있도록 관리합니다. ',
		Onsale14: '소비자의 눈높이에 맞춘 제품 사용설명 및 서비스 지원으로 고객의 만족도를 높이겠습니다. ',
		Onsale15: '프로젝트 마케팅 수요에 맞추어 고객에게 최고 품질의 표준 시공과 제품사용 서비스를 지원합니다. ',
		Aftersale1: '진심 어린 고객대응 서비스',
		Aftersale2: '5년 품질보증',
		Aftersale11: '진심 어린 고객대응 서비스는 유교문화의 정수로 진심 을 출발점으로 고객에게 즉각적인 전문적인 서비스 체험을 제공하여 천만의 고객에게 더욱 편리한 집을 느낄 수 있도록 최선을 다하겠습니다. ',
		Aftersale12: '후드, 쿡탑, 소독기, 스팀오븐/전자레인지, 스팀오븐, 오븐, 전자레인지, 식기세척기, 정수기 제품의 품질보장기간은 5년으로 고객이 더욱 안심하고 사용하도록 하겠습니다.',
		
		allting: '더 알아보기',
		informationing: '정보와 이벤트',
		squareimage: 'FOTILE 영상',
		gourmet: '창의적 레시피',
		fantings: '지난 20여년간 세계 각지의 가정에서 주방환경을 개선하고 주방의 건강을 지켜왔습니다.  수억 명 고객의 행복을 위하여 우리는 앞으로 나아갑니다. ?',
		cokintmast: 'FOTILE은 고객이 편안하고 안심하고 요리할 수  있는 주방공간을 제공하여 가족의 건강을 보호하겠습니다.',
		mostdetail:'상세내용',
		about1:'세계 주방의 행복한 선택',
		about11:'FOTILE은 고품질의 제품과 서비스를 제공하여 보다 많은 가정이 행복하고 편안한 주방을 즐길 수 있도록 건강하고 친환경적인 라이프 스타일을 만들고 있습니다. 지난 20여년간 전 세계의 가정에서 주방환경을 개선하고 주방의 건강을 지켜왔습니다. 수억의 가정의 행복을 위하여 우리는 앞으로 나아갑니다. ?',
		about2:'탕파이 연구개발과 혁신',
		about22:'주방가전의 리더인 FOTILE은 전 세계의 연구개발과 설계역량, 첨단 주방생산설비, 국제공업제조 선진기술을 보유하고 있으며, 독일, 일본, 중국 3국에 연구개발센터를 두고 있습니다. FOTILE은 2021년 3월 현재 7200여 건의 라이센스 특허를 보유하고 있으며, 이 중 발명 특허  700건 이상의 풍부한 과학적 연구 역량을 바탕으로 창조적인 실력을 확보하고 있습니다. FOTILE은 2007년 이후 iF 대상 28개, red dot 대상 22개 등 수차례 국제 디자인을 수상하는 최고의 브랜드입니다.   2021 年 3 月，方太拥有 7200 余项授权专利，其中发明专利数量超 700 项，为行业翘楚。雄厚的科研力量，确保了方太的创新实力。2007 年至今，方太共获得28 项 iF 大奖和 22 项 reddot 红点大奖等国际奖项，成为行业佼佼者。',
		about3:'건강한 주방생활',
		about33:'FOTILE의 마음은 고객의 건강입니다. 우리는 믿습니다. 주방은 단지 하루에 세끼를 완성하는 장소만이 아니라, 한 가족 모두 같이 요리의 즐거움을 찾고, 행복한 생활을 공유하며, 사랑과 즐거움을 나누는 공간이어야 한다고… 매 끼니는 단순한 생활 수요의 해결이 아니고 한 명의 위대한 요리사의 생활방식의 표현입니다. FOTILE은 당신에게 편안하고 안심할 수 있는 요리공간을 제공하여 당신과 가족의 건강을 보호하고 있습니다.  ',
		about4:'업계 선도 브랜드',
		about44:'FOTILE은 프리미엄급 주방가전의 연구개발과 생산에 전념하고 있습니다. 후드, 쿡탑, 오븐, 씽크식기세척기 등을 포함하여 주방가전 전 제품시리즈를 갖추고 있으며, 전세계 30여개 국에서 1700만 가정이 사용하는 주방가전 브랜드입니다.  2021년 현재 FOTILE은 독특한 혁신적인 모델, 깊은 브랜드 가치, 7년 연속 아시아 브랜드 500위권에 올라 전문 주방가전 1위를 차지하고 있습니다.   2020 年，方太以独特的创新模式、深厚的品牌价值，连续七年荣登亚洲品牌 500 强，位居厨电行业第一。',
		
		registering:'고객정보',
		nottoregis:'FOTILE은 고객의 개인정보를 누설하지 않습니다.',
		kehunews1:'성',
		kehunews2:'이름',
		kehunews3:'전화 번호',
		kehunews4:'이메일',
		kehunews5:'주소',
		kehunews6:'아파트, 펜트하우스 등',
		kehunews7:'도시',
		kehunews8:'상태',
		kehunews9:'우편번호',
		kehunews10:'카테고리 선택',
		kehunews11:'모델 넘버 선택',
		kehunews12:'제품번호',
		kehunews13:'FOTILE 제품을 구매할 수 있는 곳',
		kehunews14:'직접 선택하거나 검색하여 선택',
		kehunews15:'구매일자',
		kehunews16: '대리점/아마존영수증/주문번',
		nowtoregister:'현재 등록',
		personal:'개인정보',
		toubuy:'제품구매',
		halsthy:'건강한 주방생활',
		alwaysanli:'FOTILE은 그동안 고품질의 제품과 서비스를 제공하여 보다 많은 가정이 행복하고 편안한 주방생활을 즐길 수 있도록 건강하고 친환경적인 라이프스타일을 만들어 왔습니다.  ',
		joninene:'가입구독 FOTILE브리',
		ionudd:'저희의 메일을 등록하시고 신제품과 특별할인 및 할인 코드 등을 받으세요.',
		
		joininn:'가입',
		seriesing:'시리즈 소개',
		
		feedback1: '이름',
		feedback2: '국가',
		feedback3: '도시',
		feedback4: '전화',
		feedback5: '문제',
		wantfaku:'피드백과 제안을 원합니다',
		wantfakuson: '많은 도움과 지원 부탁드립니다!',
		tofaku: '제출하다',
		
shiname:'FOTILE은 미션, 비전, 가치를 추구하는 기업입니다.',

		hospion:'',
		
borderred:'이 필드를 작성하십시오',
sucssfil:'제출 성공',

		








		





	}
}
