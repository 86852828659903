<template>
  <div class="exhibition">
    <div class="hibitiontop">
      <div class="hibitionimg wow animate fadeInUp">
        <img class="immmon" src="../assets/img/alltitle/bigimg.jpg" alt="" />
      </div>

      <div class="centernav wow animate fadeInUp delay-300ms">
        <div class="centernavinner">
          <div class="selectarea">
            <!-- <div class="areapear">
              <div class="mapclassfather">
                <img
                  class="mapclass"
                  src="../assets/img/alltitle/mapding.png"
                  alt=""
                />
                <span class="setect">Select Area</span>
              </div>

              <img
                class="bottnick"
                src="../assets/img/alltitle/xxiang.png"
                alt=""
              />
            </div>

            <div class="areapear">
              <div class="mapclassfather">
                <img
                  class="mapclass"
                  src="../assets/img/alltitle/mapding.png"
                  alt=""
                />
                <span class="setect">Select Area</span>
              </div>

              <img
                class="bottnick"
                src="../assets/img/alltitle/xxiang.png"
                alt=""
              />
            </div>

            <div class="areapear">
              <div class="mapclassfather">
                <img
                  class="mapclass"
                  src="../assets/img/alltitle/mapding.png"
                  alt=""
                />
                <span class="setect">Select Area</span>
              </div>

              <img
                class="bottnick"
                src="../assets/img/alltitle/xxiang.png"
                alt=""
              />
            </div> -->
            <img
              class="mapclass"
              src="../assets/img/alltitle/mapding.png"
              alt=""
            />
            <el-select
              v-model="value"
              :placeholder="placeholder1"
              @change="choosearea"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <div class="rightlion"></div>
            <img
              class="mapclass"
              src="../assets/img/alltitle/mapding.png"
              alt=""
            />
            <el-select
              v-model="two"
              :placeholder="placeholder2"
              @focus="plcechose"
              @change="choosesencond"
            >
              <el-option
                v-for="two in sencondselect"
                :key="two.value"
                :label="two.name"
                :value="two.name"
              >
              </el-option>
            </el-select>
            <div class="rightlion"></div>
            <img
              class="mapclass"
              src="../assets/img/alltitle/mapding.png"
              alt=""
            />
            <el-select
              v-model="there"
              :placeholder="placeholder3"
              @focus="behind"
              @change="choolast"
            >
              <el-option
                v-for="there in thirdselect"
                :key="there.value"
                :label="there.name"
                :value="there.name"
              >
              </el-option>
            </el-select>
            <!-- <div class="rightlion"></div> -->
          </div>

          <div class="selectblack" @click.stop="toinquery">
            {{ $t("login.ochaxue") }}
          </div>
        </div>
      </div>

      <div class="monkeyfahter wow animate fadeInUp delay-600ms">
        <!-- <img class="imgfae" src="../assets/img/alltitle/monkey.png" alt=""> -->
        <div class="monkeybott">
          <div
            class="fistmonkeychild"
            v-for="item in bottomlist"
            :key="item.id"
          >
            <div class="copyrigo">
              <img
                class="imgoned"
                src="../assets/img/alltitle/copyin.png"
                alt=""
              />
              <span>{{ item.name }}</span>
            </div>
            <div class="telandfax telandfaxtwo">{{ item.tel }}</div>
            <!-- <div class="telandfax">Name: {{ item.name }}</div> -->
            <div class="telandfax">
              <!-- HR&G Co.,Ltd, A-451, 140, Tongil-ro, Deogyang-gu, -->
            </div>
            <div class="telandfax">
              <!-- Goyang-si, Gyeonggi-do, Republic of Korea -->
              {{ item.addr }}
            </div>
          </div>
          <div class="fistmonkeychild"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import {
  getdealer,
  getztcountrylist,
  getcitylist,
  getshoplist,
} from "../request/api";

export default {
  data() {
    return {
      options: [
        // {
        //   value: "选项1",
        //   label: "Asia",
        // },
        // {
        //   value: "选项2",
        //   label: "Australia",
        // },
        // {
        //   value: "选项3",
        //   label: "North America",
        // },
      ],
      sencondselect: [],
      thirdselect: [],
      value: "",
      two: "",
      there: "",
      flage: "",
      diffent: "",
      nextid: "",
      objmesage: "",
      objlang: "",
      queryid: "",
      inquid: "",
      bottomlist: [],
    };
  },
  created() {
    if (sessionStorage.getItem("newlangusge") == null) {
      this.outdealer("nz", "en");
      this.objmesage = "nz";
      this.objlang = "en";
    } else {
      this.outdealer("nz", sessionStorage.getItem("newlangusge"));
      this.objmesage = "nz";
      this.objlang = sessionStorage.getItem("newlangusge");
    }
    this.$route.meta.title = this.$t("login.querythere7");
    this.$router.replace({
      query: {
        temp: Date.now(), //必有
      },
    });
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      this.objmesage = message;
      this.objlang = b;
      this.outdealer(message, b);
      this.$route.meta.title = this.$t("login.querythere7");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  computed: {
    placeholder1() {
      return this.$t("login.pleachoose");
    },
    placeholder2() {
      return this.$t("login.pleachoose");
    },
    placeholder3() {
      return this.$t("login.pleachoose");
    },
  },

  methods: {
    outshopping(a, b, c) {
      getshoplist({
        lang: b,
        country: a,
        city_id: c,
      }).then((res) => {
        console.log(res);
        this.bottomlist = res.data;
      });
    },
    outcitylist(a, b) {
      getcitylist({
        lang: a,
        country_id: b,
      }).then((res) => {
        console.log(res);
        this.thirdselect = res.data;
      });
    },
    outcountrylist(a, b) {
      getztcountrylist({
        lang: a,
        area_id: b,
        country: "nz",
      }).then((res) => {
        console.log(res);
        this.sencondselect = res.data;
      });
    },
    outdealer(a, b) {
      getdealer({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.options = res.data.area;
      });
    },
    toinquery() {
      console.log(111111);
      if (this.value == "" || this.two == "" || this.there == "") {
        // this.$message.error("Please select the full address");
        this.$message({
          message: "Please select the full address",
          type: "error",
          offset: 90,
        });
      } else {
        setTimeout(() => {
          // this.value = "";
          // this.two = "";
          // this.there = "";
          // this.$message.success("success");
          this.$message({
            message: "success",
            type: "success",
            offset: 90,
          });
        }, 1000);
        this.outshopping(this.objmesage, this.objlang, this.inquid);
      }
    },
    choosearea(el) {
      console.log(el);
      this.flage = el;
      this.two = "";
      this.there = "";
      this.options.forEach((val) => {
        if (this.flage == val.name) {
          this.nextid = val.id;
        }
      });
      console.log(this.nextid);
    },
    plcechose() {
      console.log(this.flage);
      this.outcountrylist(this.objlang, this.nextid);
      // if (this.flage == "选项1") {
      //   this.sencondselect = [
      //     {
      //       value: "1",
      //       label: "Malaysia",
      //     },
      //     {
      //       value: "2",
      //       label: "Pakistan",
      //     },
      //   ];
      // } else if (this.flage == "选项2") {
      //   this.sencondselect = [
      //     {
      //       value: "3",
      //       label: "Australia",
      //     },
      //   ];
      // } else if (this.flage == "选项3") {
      //   this.sencondselect = [
      //     {
      //       value: "4",
      //       label: "America",
      //     },
      //     {
      //       value: "5",
      //       label: "Canada",
      //     },
      //   ];
      // }
    },
    choosesencond(el) {
      console.log(el);
      this.diffent = el;
      this.there = "";
      this.sencondselect.forEach((val) => {
        if (this.diffent == val.name) {
          this.queryid = val.id;
        }
      });
      console.log(this.queryid);
    },
    behind() {
      this.outcitylist(this.objlang, this.queryid);
      // if (this.diffent == 1) {
      //   this.thirdselect = [
      //     {
      //       value: "1",
      //       label: "Kuala Lumpur",
      //     },
      //     {
      //       value: "2",
      //       label: "Saint John",
      //     },
      //     {
      //       value: "3",
      //       label: "韩国",
      //     },
      //     {
      //       value: "4",
      //       label: "Korea",
      //     },
      //   ];
      // } else if (this.diffent == 2) {
      //   this.thirdselect = [
      //     {
      //       value: "1",
      //       label: "KARACHI",
      //     },
      //     {
      //       value: "2",
      //       label: "ISLAMABAD",
      //     },
      //     {
      //       value: "3",
      //       label: "LAHORE",
      //     },
      //   ];
      // } else if (this.diffent == 3) {
      //   this.thirdselect = [
      //     {
      //       value: "1",
      //       label: "Melbourne",
      //     },
      //     {
      //       value: "2",
      //       label: "Sydney",
      //     },
      //     {
      //       value: "3",
      //       label: "Perth",
      //     },
      //     {
      //       value: "4",
      //       label: "Brisbane",
      //     },
      //   ];
      // } else if (this.diffent == 4) {
      //   this.thirdselect = [
      //     {
      //       value: "1",
      //       label: "New York NY",
      //     },
      //     {
      //       value: "2",
      //       label: "New York NY",
      //     },
      //     {
      //       value: "3",
      //       label: "San Francisco CA",
      //     },
      //   ];
      // } else if (this.diffent == 5) {
      //   this.thirdselect = [
      //     {
      //       value: "1",
      //       label: "Vancouver",
      //     },
      //     {
      //       value: "2",
      //       label: "Los Angeles",
      //     },
      //     {
      //       value: "3",
      //       label: "Great Toronto Area",
      //     },
      //   ];
      // }
    },
    choolast(el) {
      console.log(el);
      this.thirdselect.forEach((val) => {
        if (el == val.name) {
          this.inquid = val.id;
        }
      });
      console.log(this.inquid);
    },
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
};
</script>
<style lang="less" scoped>
.exhibition {
  margin-bottom: 50px;
  .hibitiontop {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .hibitionimg {
      .immmon {
        width: 100%;
        // height: 840px;
      }
    }
    .centernav {
      margin: 0 auto;
      width: 1200px;
      height: 100px;
      background: #ffffff;
      box-shadow: 0px 7px 10px 0px rgba(117, 117, 117, 0.09);
      border-radius: 3px;
      margin-top: -60px;
      .centernavinner {
        margin: 25px;
        display: flex;
        .selectarea {
          width: 986px;
          height: 46px;
          background-color: #f7f7f7;
          border-radius: 3px 0px 0px 3px;
          display: flex;
          align-items: center;
          border: 1px solid #f2f2f2;
          /deep/ .el-select .el-input__inner {
            background: #f5f5f5;
            // border: 1px solid #e1e1e1;
            border: none;
            height: 48px;
            width: 300px;
            border-radius: 7px;
          }
          /deep/ .el-select__caret {
            -webkit-transform: none;
            transform: none;
          }

          /deep/ .el-icon-arrow-up:before {
            content: "\e790";
          }
          .mapclass {
            margin-left: 28px;
            display: none;
          }
          .rightlion {
            width: 1px;
            height: 12px;

            background: #898989;
            margin-left: 20px;
            margin-right: 12px;
            opacity: 0.2;
          }
        }
        // .selectarea {
        //   width: 986px;
        //   height: 46px;
        //   background-color: #f7f7f7;
        //   border-radius: 3px 0px 0px 3px;
        //   display: flex;
        //   align-items: center;
        //   border: 1px solid #f2f2f2;
        //   .areapear {
        //     display: flex;
        //     width: 300px;
        //     // height: 65px;
        //     justify-content: space-between;
        //     margin-left: 28px;
        //     border-right: 1px solid #e1e1e1;
        //     padding-right: 30px;
        //     align-items: center;
        //     .mapclassfather {
        //       .mapclass {
        //         width: 14px;
        //         height: 14px;
        //         vertical-align: middle;
        //       }
        //       .setect {
        //         margin-left: 9px;
        //         vertical-align: middle;
        //         font-size: 12px;
        //         // font-family: Arial;
        //         font-weight: 400;
        //         color: #898989;
        //       }
        //     }
        //     .bottnick {
        //       width: 14px;
        //       height: 14px;
        //       vertical-align: middle;
        //     }
        //   }
        //   .areapear:last-child {
        //     border-right: none;
        //   }
        // }
        .selectblack {
          cursor: pointer;
          width: 165px;
          height: 46px;
          font-size: 14px;
          font-weight: bold;
          background: #131e29;
          border: 1px solid #131e29;
          border-radius: 0px 3px 3px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
        }
        .selectblack:hover {
          background: #9d2736;
          border: 1px solid #9d2736;
        }
      }
    }
    .monkeyfahter {
      width: 1200px;
      margin: 0 auto;
      padding-top: 100px;
      .imgfae {
        margin-top: 66px;
        margin-bottom: 66px;
        width: 100%;
        // height: 532px;
      }
      .monkeybott {
        display: flex;
        justify-content: space-between;
        // margin-top:42px;
        flex-wrap: wrap;
        .fistmonkeychild {
          margin-bottom: 60px;
          width: 30%;
          .copyrigo {
            display: flex;
            align-items: center;
            .imgoned {
              width: 24px;
              // height: 40px;
              // vertical-align: middle;
            }
            span {
              // margin-top: 20px;
              // vertical-align: bottom;
              margin-left: 10px;
              font-size: 18px;
              // font-family: Arial;
              font-weight: bold;
              color: #000000;
            }
          }
          .telandfax {
            font-size: 12px;
            // font-family: Arial;
            font-weight: 400;
            color: #000000;
            line-height: 24px;
            margin-left: 32px;
          }
          .telandfaxtwo {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
</style>