<template>
  <div class="newoutside">
    <div class="newinner">
      <div class="newtime">{{ manynew.publishdate }}&nbsp&nbsp&nbsp&nbsp</div>
      <div class="newtitle">
        <!-- BEHIND THE "SCIENCE AND TECHNOLOGY PROGRESS AWARD" AT THE NOBEL PRIZE
        WINNER MEDICAL SUMMIT, FANG TAI ONCE AGAIN LEVERAGED THE DEVELOPMENT OF
        THE INDUSTRY WITH HIS ORIGINAL INVENTION -->
        {{ manynew.title }}
      </div>
      <div class="peopleimg">
        <!-- <img src="../assets/img/alltitle/people.png" alt="" /> -->
        <!-- <img :src="manynew.img" alt="" /> -->
        <video controls :src="manynew.video_url" type="video/mp4">
          <!-- <source :src="manynew.video_url" type="video/mp4" /> -->
        </video>
      </div>
      <div class="newcontent" v-html="manynew.content">
        <!-- ROI award, the first business innovation award in the world, is very
        authoritative and influential in innovation field. It’s set to award the
        most innovative brands and enterprises, and thus represents the vogue
        for innovation of the year. This year, Seven-Eleven won the first prize
        by virtue of its innovation and products such as “7 Things I’ve learned
        from Being Single”, Coca-Cola lyrics bottles and Go-Pro. Products from
        20 countries and districts competed for the 16 awards of ROI. FOTILE
        sink dishwasher, designed as a globally unprecedented integration of
        dishwasher, sink and fruit and vegetable cleaning machine, was the only
        domestic brand to win “Best of the Best Award for Production Design
        Team” in the 8th ROI Festival in 2015.ROI award, the first business
        innovation award in the world, is very authoritative and influential in
        innovation field. It’s set to award the most innovative brands and
        enterprises. and thus represents the vogue for innovation of the year.
        This year, Seven-Eleven won the first prize by virtue of its innovation
        and products such as “7 Things I’ve learned from Being Single”,
        Coca-Cola lyrics bottles and Go-Pro. Products from 20 countries and
        districts competed for the 16 awards of ROI. FOTILE sink dishwasher,
        designed as a globally unprecedented integration of dishwasher, sink and
        fruit and vegetable cleaning machine, was the only domestic brand to win
        “Best of the Best Award for Production Design Team” in the 8th ROI
        Festival in 2015. -->
        {{ manynew.intro }}
      </div>
      <!-- <div class="peopleimg">
        <img src="../assets/img/alltitle/peotwo.png" alt="" />
      </div>
      <div class="newcontent newcontenttwo">
        This year, Seven-Eleven won the first prize by virtue of its innovation
        and products such as “7 Things I’ve learned from Being Single”,
        Coca-Cola lyrics bottles and Go-Pro. Products from 20 countries and
        districts competed for the 16 awards of ROI. FOTILE sink dishwasher,
        designed as a globally unprecedented integration of dishwasher, sink and
        fruit and vegetable cleaning machine, was the only domestic brand to win
        “Best of the Best Award for Production Design Team” in the 8th ROI
        Festival in 2015. This year, Seven-Eleven won the first prize by virtue
        of its innovation and products such as “7 Things I’ve learned from Being
        Single”, Coca-Cola lyrics bottles and Go-Pro. Products from 20 countries
        and districts competed for the 16 awards of ROI. FOTILE sink dishwasher,
        designed as a globally unprecedented integration of dishwasher, sink and
        fruit and vegetable cleaning machine, was the only domestic brand to win
        “Best of the Best Award for Production Design Team” in the 8th ROI
        Festival in 2015.
      </div> -->
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import { getvideodetail } from "../request/api";

export default {
  data() {
    return {
      manynew: {},
    };
  },
  created() {
    console.log(this.$route.query.id);

    if (sessionStorage.getItem("newlangusge") == null) {
      this.outdets("nz", "en", "d", this.$route.query.id);
    } else {
      this.outdets(
        "nz",
        sessionStorage.getItem("newlangusge"),
        "d",
        this.$route.query.id
      );
    }
    this.$route.meta.title = this.$t("login.querythere16");
    this.$router.replace({
      query: {
        temp: Date.now(), //必有
        id: this.$route.query.id,
      },
    });
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      console.log(d);
      this.outdets(message, b, d, this.$route.query.id);
      this.$route.meta.title = this.$t("login.querythere16");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
          id: this.$route.query.id,
        },
      });
    });
  },
  methods: {
    outdets(a, b, c, d) {
      getvideodetail({
        lang: b,
        country: a,
        id: d,
      }).then((res) => {
        console.log(res);
        this.manynew = res.data;
      });
    },
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // ��dom��Ⱦ�����ִ�ж���
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
};
</script>
<style lang="less" scoped>
.newoutside {
  .newinner {
    width: 1200px;
    margin: 0 auto;
    .newtime {
      margin-top: 52px;
      font-size: 12px;
      font-family: Helvetica Neue LT Pro;
      font-weight: normal;
      color: #474545;
    }
    .newtitle {
      margin-top: 42px;
      font-size: 19px;
      // font-family: Arial;
      font-weight: bold;
      color: #000000;
      line-height: 30px;
    }
    .peopleimg {
      img {
        width: 1200px;
        height: 600px;
        margin-top: 50px;
      }
      video {
        margin-top: 50px;
        // height: 600px;
        width: 1200px;
      }
    }
    .newcontent {
      margin-top: 50px;
      font-size: 12px;
      // font-family: PingFang SC;
      font-weight: 400;
      color: #151515;
      line-height: 25px;
      padding-bottom: 50px;
    }
    .newcontenttwo {
      margin-bottom: 200px;
    }
  }
}
</style>