<template>
  <div class="detaileverthing wow animate fadeInUp" id="printMe">
    <div class="detailcenter">
      <div class="deleftimg">
        <el-carousel
          indicator-position="outside"
          height="400px"
          :autoplay="false"
          arrow="never"
          :loop="false"
          trigger="click"
        >
          <el-carousel-item v-for="item in probanlist" :key="item.id">
            <img class="delunbo" :src="item" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="derightfont">
        <div class="DOMOfirst">
          <img src="../assets/img/global/icon_02.png" alt="" />
          <!-- <div class="imgbotti">Intelligent self-adjusting</div> -->
          <div class="imgbotti" v-for="item in detail.tags" :key="item.id">
            {{ item }}
          </div>
          <!-- <div class="dcmotor">Dc motor</div> -->
        </div>
        <!-- <div class="stardwid">EMG9035</div> -->
        <div class="stardwid">{{ detail.code }}</div>
        <div class="breaks">
          <!-- Super powerful turbo exhaust, a new experience of quieter and more
          efficient extraction experience of quieter turbo exhaust -->
          {{ detail.intro }}
        </div>
        <!-- <div class="haveiconaway">
          <div class="havesuc" v-for="item in detail.award" :key="item.id">
            <template v-if="award.length != 0">
              <img :src="item" alt="" />
            </template>
          </div>
        </div> -->
        <!-- <div class="covideo" @click="tovideo(fourdata.detail.video)"> -->
        <div
          class="covideo"
          @click="tovideo()"
          v-if="fourdata.detail.video != ''"
        >
          <img src="../assets/img/detailimg/bofang.png" alt="" />
          <span>{{ $t("login.shipinvideo") }}</span>
        </div>
        <div class="covideo covideotwo">
          <img src="../assets/img/detailimg/facct.png" alt="" />
          <span v-print="'#printMe'">{{ $t("login.dayijin") }}</span>
        </div>
        <div class="likerow" @click="toshoppig" v-if="buy_link != ''">
          <span>{{ $t("login.masgomai") }}</span>
        </div>
      </div>
    </div>
    <div class="secondbox">
      <div class="nav-bar" :class="{ is_fixed: isFixed }">
        <div class="container">
          <div class="manualleft">
            <!-- <div
              :class="isover == 1 ? 'proactive' : 'pro-title'"
              @click="anthth((isover = 1), link)"
            >
              {{ $t("login.features") }}
            </div> -->
            <div
              :class="isover == 2 ? 'proactive' : 'pro-title'"
              @click="changetitle((isover = 2))"
            >
              {{ $t("login.prodetail") }}
            </div>
            <div
              :class="isover == 3 ? 'proactive' : 'pro-title'"
              @click="changetitle((isover = 3))"
            >
              {{ $t("login.teparnters") }}
            </div>
            <div
              :class="isover == 4 ? 'proactive' : 'pro-title'"
              @click="changetitle((isover = 4))"
            >
              {{ $t("login.installation") }}
            </div>
            <div
              :class="isover == 5 ? 'proactive' : 'pro-title'"
              @click="changetitle((isover = 5))"
            >
              {{ $t("login.manual") }}
            </div>
            <div
              :class="isover == 6 ? 'proactive' : 'pro-title'"
              @click="changetitle((isover = 6))"
            >
              <!-- Comment -->
            </div>
          </div>
          <div class="manualfight">
            <div class="buysongthing" @click="toshoppig" v-if="buy_link != ''">
              <img src="../assets/img/alltitle/shoppcar.png" alt="" />
              <span>{{ $t("login.tobuy") }}</span>
            </div>
            <!-- <div class="Compared">{{ $t("login.moreandmore") }}</div> -->
            <!-- <div class="Compared">more</div> -->
          </div>
        </div>
      </div>
      <!-- <tezheng v-show="isover == 1" :link="link" /> -->
      <detailone v-show="isover == 2" :innerdetails="innerdetails" />
      <Parameters v-show="isover == 3" :param="param" :spec_img="spec_img" />
      <Diagram
        v-show="isover == 4"
        :titlim="detail.scene"
        :forexample="detail.install_pc"
      />
      <autograph
        v-show="isover == 5"
        :brochure="brochure"
        :manual="manual"
        :spec_sheet="spec_sheet"
        :warranty_document="warranty_document"
        :accessories_lists="accessories_lists"
        :menu_dow="menu_dow"
        :design_file="design_file"
      />
      <!-- <Comment v-show="isover == 5" /> -->
      <recimmend v-show="isover != 6" :recommend="recommend" />
    </div>

    <!-- // template -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="900px"
      height="480"
      :show-close="false"
      :before-close="handleClose"
    >
      <el-divider />
      <video id="video" controls width="900" height="480">
        <source :src="fourdata.detail.video" type="video/mp4" />
      </video>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">
          $t("login.cancel")</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import { getproductDatat } from "../request/api";

import tezheng from "../components/tezheng";
import detailone from "../components/detailone";
import recimmend from "../components/recimmend";
import Parameters from "../components/Parameters";
import Diagram from "../components/Diagram";
import autograph from "../components/autograph";
// import Comment from "../components/Comment";

export default {
  components: {
    detailone,
    recimmend,
    Parameters,
    Diagram,
    autograph,
    Comment,
    tezheng,
  },
  data() {
    return {
      isFixed: false,
      isover: 2,
      fourdata: {},
      probanlist: [],
      detail: {},
      param: [],
      spec_img: "",
      innerdetails: [],
      recommend: [],
      dialogVisible: false,
      link: "",
      lnameid: "",
      lcate: "",
      brochure: "",
      manual: "",
      spec_sheet: "",
      warranty_document: "",
      accessories_lists: "",
      menu_dow: "",
      design_file: "",
      buy_link: "",
    };
  },
  // components: {},
  beforcreate() {
    this.$route.meta.title = this.$route.meta.title + this.$route.query.id;
  },

  created() {
    console.log(this.$route.query.id);
    this.$route.meta.title = this.$route.meta.title + this.$route.query.id;

    // console.log(
    //   (this.$route.meta.title = this.$route.meta.title + this.$route.query.id)
    // );

    if (sessionStorage.getItem("newlangusge") == null) {
      this.outpadate("nz", "en", this.$route.query.id);
    } else {
      this.outpadate(
        "nz",
        sessionStorage.getItem("newlangusge"),
        this.$route.query.id
      );
    }
    setTimeout(() => {
      this.$route.meta.title = this.$t("login.querythere4");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
          id: this.$route.query.id,
        },
      });
    }, 10);
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      this.outpadate(message, b, this.$route.query.id);
      this.$route.meta.title = this.$t("login.querythere4");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
          id: this.$route.query.id,
        },
      });
    });
  },
  methods: {
    toshoppig(a) {
      var url = this.buy_link;
      window.open(url, "_blank");
    },
    handleClose() {
      this.dialogVisible = false;
      // 关闭弹出框时 视频关闭播放
      const video = document.getElementById("video");
      video.pause();
    },

    outpadate(a, b, c) {
      getproductDatat({
        code: c,
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.fourdata = res.data;
        console.log(this.fourdata);
        this.probanlist = res.data.detail.banner;
        this.detail = res.data.detail;
        this.param = res.data.detail.param;
        this.spec_img = res.data.detail.spec_img;

        this.innerdetails = res.data.detail.details;
        this.recommend = res.data.recommend;
        this.award = res.data.detail.award;
        this.link = res.data.detail.link;
        this.lnameid = res.data.detail.series_id;
        this.lcate = res.data.cate;
        console.log(this.lcate);
        this.brochure = res.data.detail.brochure;
        this.manual = res.data.detail.manual;
        this.spec_sheet = res.data.detail.spec_sheet;
        this.warranty_document = res.data.detail.warranty_document;
        this.accessories_lists = res.data.detail.accessories_lists;
        this.menu_dow = res.data.detail.menu_dow;
        this.design_file = res.data.detail.installation_guide;
        this.buy_link = res.data.detail.buy_link;
      });
    },
    tovideo(e) {
      // console.log(e);
      // var url = e;
      // // window.localtion.href = "https://www.baidu.com/?tn=40020637_14_oem_dg";
      // window.open(url, "_blank");

      this.dialogVisible = true;
    },
    initHeight() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.isFixed = scrollTop > 660 ? true : false;
    },
    // 当离开当前页面时会自动销毁this.initHeight,
    destroyed() {
      window.removeEventListener("scroll", this.initHeight, false);
    },
    changetitle(val) {
      this.isover = val;

      if (document.documentElement.scrollTop > 660) {
        document.documentElement.scrollTop = 660;
      }
    },
    anthth(val, a) {
      this.isover = val;
      console.log(a);
      this.$router.push({
        path: "/otherwebsite",
        query: { id: a, type: 1, name: this.lnameid, cate: this.lcate },
      });
    },
  },
  mounted() {
    this.$bus.$on("changedetail", (a) => {
      if (sessionStorage.getItem("newlangusge") == null) {
        this.outpadate("nz", "en", a);
      } else {
        this.outpadate("nz", sessionStorage.getItem("newlangusge"), a);
      }
    });
    this.$fourdata();
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    window.addEventListener("scroll", this.initHeight);
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
};
</script>
<style lang='less' scoped>
/deep/ .deleftimg .el-carousel__button {
  opacity: 1;
  width: 6px;
  height: 6px;
  border-radius: 10px;
}
/deep/
  .el-carousel__indicator.el-carousel__indicator--horizontal.is-active
  .el-carousel__button {
  background: #992025;
  width: 10px !important;
}
/deep/ .el-carousel__item.is-animating {
  text-align: center;
}
/deep/ .el-carousel__indicators--outside {
}

.detaileverthing {
  background-color: #f8f8f8;
  .detailcenter {
    width: 1100px;
    height: 550px;
    padding-top: 40px;
    margin: 0 auto;
    display: flex;

    // background-color: red;
    .deleftimg {
      width: 500px;
      .delunbo {
        width: 75%;
        margin: 0 auto;
        margin-top: 30px;
        /* height: 500px; */
      }
    }
    .derightfont {
      margin-left: 120px;
      margin-top: 22px;
      display: flex;
      flex-direction: column;
      .DOMOfirst {
        display: flex;
        flex-wrap: wrap;
        img {
          /* width: 48px; */
          height: 25px;
          margin: -2px 0;
          position: relative;
          top: 2px;
        }
        .imgbotti {
          /* width: 150px; */
          height: 24px;
          padding: 0 12px;
          border: 1px solid #b5b5b5;
          /* opacity: 0.5; */
          border-radius: 5px;
          font-size: 12px;
          /* font-family: Arial; */
          font-weight: 400;
          color: #b5b5b5;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          margin-bottom: 20px;
        }
        .dcmotor {
          /* width: 70px; */
          height: 24px;
          padding: 0 12px;
          border: 1px solid #b5b5b5;
          /* opacity: 0.5; */
          border-radius: 5px;
          font-size: 12px;
          /* font-family: Arial; */
          font-weight: 400;
          color: #b5b5b5;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
        }
      }
      .stardwid {
        font-size: 32px;
        font-weight: bolder;
        color: #000000;
        margin-top: 15px;
      }
      .breaks {
        width: 500px;
        font-size: 13px;
        /* font-family: PingFang SC; */
        font-weight: 300;
        color: #151515;
        line-height: 20px;
        margin-top: 10px;
      }
      .haveiconaway {
        display: flex;
        overflow: hidden;
        margin-top: 25px;
        // justify-content: space-around;
        width: 360px;
        .havesuc {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 70px;
            height: 70px;
            margin-right: 30px;
          }
          .oreddeif {
            margin-top: 10px;
            // width: 80px;
            text-align: center;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .covideo {
        margin-top: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 18px;
          /* height: 20px; */
        }
        span {
          font-size: 13px;
          /* font-family: Arial; */
          font-weight: 400;
          text-decoration: underline;
          color: #000000;
          margin-left: 10px;
        }
        span:hover {
          color: #9d2736;
          text-decoration: none;
        }
      }
      .covideotwo {
        margin-top: 20px;
      }

      .likerow {
        margin-top: 35px;
        width: 120px;
        height: 33px;
        border: 1px solid #010101;
        cursor: pointer;
        /* opacity: 0.82; */
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url(../assets/img/alltitle/littlein.png) no-repeat 80% 50%;
        background-size: 12px;
        span {
          font-size: 12px;
          /* font-family: Arial; */
          /* font-weight: bold; */
          color: #000000;
          margin-right: 20px;
        }
        img {
          margin-left: 30px;
          width: 24px;
          height: 24px;
        }
      }
      .likerow:hover {
        border: 1px solid #9e1d22;
        background: url(../assets/img/alltitle/whiterow.png) no-repeat 85% 50%;
        background-size: 12px;
        background-color: #9e1d22;
        span {
          color: white;
        }
      }
    }
  }
  .secondbox {
    // height: 800px;
    width: 100%;
    background-color: white;
    .nav-bar {
      height: 70px;
      line-height: 70px;
      border-bottom: 1px solid #f2f2f2;
      border-top: 1px solid #f2f2f2;
      background-color: #fff;
      &.is_fixed {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;
        /* box-shadow: 0 5px 5px #ccc;
		background-color: white; */
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
      }
      .container {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .manualleft {
          width: 744px;
          display: flex;
          justify-content: space-between;
          .pro-title {
            font-size: 14px;
            /* font-family: Arial; */
            font-weight: 400;
            color: #000000;
            cursor: pointer;
          }
          .pro-title:hover {
            color: #9e2b29;
          }
          .proactive {
            color: #9e2b29;
            font-size: 14px;
            /* font-family: Arial; */
            font-weight: bold;
            cursor: pointer;
          }
        }
        .manualfight {
          width: 456px;
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          .Compared {
            cursor: pointer;
            width: 101px;
            height: 31px;
            border: 1px solid #9e2b29;
            border-radius: 3px;
            font-size: 14px;
            /* font-family: PingFang SC; */
            font-weight: 400;
            color: #9e2b29;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .Compared:hover {
            border: 1px solid #000000;
            background: #000000;
            color: #ffffff;
          }

          .buysongthing {
            margin-left: 10px;
            cursor: pointer;
            width: 89px;
            height: 33px;
            background: #9e2b29;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            img {
              width: 14px;
              /* height: 14px; */
            }
            span {
              margin-left: 5px;
              font-size: 14px;
              /* font-family: Arial; */
              font-weight: 400;
              color: #ffffff;
            }
          }
          .buysongthing:hover {
            background: #000000;
          }
        }
      }
    }
  }
}
/deep/ .el-dialog__header {
  padding: 0px;
}
/deep/.el-dialog__body {
  padding: 0px;
}
</style>