<template>
  <div class="allcontainer">
    <div class="centerwidth">
      <div class="firstwird" v-if="firstlist.length != 0">
        <div class="firstcontent wow animate fadeInUp delay-400ms">
          {{ $t("login.newactive") }}
        </div>
        <div class="secondcontent wow animate fadeInUp delay-500ms">
          <div class="context">
            {{ $t("login.newmodel") }}
          </div>
          <!-- <div class="conarrow">
            <div class="conall">{{ $t("login.moreandmore") }}</div>
            <img class="likearrow" src="../assets/img/alltitle/rowarrow.png" />
          </div> -->
        </div>
        <div class="theretext">
          <div
            class="
              newspaper
              fadeInUp
              animatechangeone
              wow
              animate
              fadeInUp
              delay-600ms
            "
            v-for="item in firstlist"
            :key="item.id"
            @click="todetail(item.id)"
          >
            <div class="paperinner">
              <div class="paperhead">
                <!-- <div class="papertime">2021.05.20</div> -->
                <div class="papertime">{{ item.publishdate }}</div>
                <!-- <div class="paperfont">Company news</div> -->
              </div>
              <div class="fontbig">
                <!-- FAMGTAI IN CULTURE DHFHADKF DKFKFK RESEARCH INSTITYTE -->
                {{ item.title }}
              </div>
              <div class="imgandfont">
                <!-- <img class="leftimgs" src="../assets/img/alltitle/new01.jpg" /> -->
                <img class="leftimgs" :src="item.img" />
                <div class="rightfont">
                  <!-- Fangtai Culture Research Institute is a disseminates Fangtai
                  Culture Research Institute is a disseminates excellen tzation
                  non-profit pate nental organ ization disseminates excellent -->
                  {{ item.intro }}
                </div>
              </div>
              <div class="keoutdoor">
                <div class="learnmore">{{ $t("login.mostdetail") }}</div>
              </div>
            </div>
          </div>
          <div class="othernews" v-if="firstlist.length < 3"></div>
        </div>
      </div>

      <div
        class="firstwird wow animate fadeInUp"
        v-if="secondlisst.length != 0"
      >
        <div class="firstcontent wow animate fadeInUp">
          {{ $t("login.squareimage") }}
        </div>
        <div class="secondcontent wow animate fadeInUp delay-100ms">
          <div class="context">
            {{ $t("login.fantings") }}
          </div>
          <!-- <div class="conarrow">
            <div class="conall">{{ $t("login.allting") }}</div>
            <img class="likearrow" src="../assets/img/alltitle/rowarrow.png" />
          </div> -->
        </div>
        <div class="theretext">
          <div
            class="
              newspaper
              newspapertwo
              fadeInUp
              animatechangefourwow
              animate
              fadeInUp
              delay-200ms
            "
            v-for="item in secondlisst"
            :key="item.id"
            @click="tovideodetail(item.id)"
          >
            <div class="paperinner">
              <div class="paperhead">
                <!-- <div class="papertime">2021.05.20</div> -->
                <div class="papertime">{{ item.publishdate }}</div>
                <!-- <div class="paperfont">Company news</div> -->
              </div>
              <div class="fontbig">
                <!-- BIG CULTURE RESEARCH INSTITYTE FAMGTAI IN CULTURE RESEARCH AND
                LOVE... -->
                {{ item.title }}
              </div>
              <div class="imgBox">
                <!-- <img
                  class="twoimgscopy"
                  src="../assets/img/alltitle/yx-01.jpg"
                /> -->

                <video :src="item.video_url" class="namethere"></video>
                <img src="../assets/img/alltitle/video.png" class="videoIcon" />
                <div class="zz"></div>
              </div>
              <div class="keoutdoor">
                <div class="learnmore">{{ $t("login.mostdetail") }}</div>
              </div>
            </div>
          </div>
          <div class="othernews" v-if="secondlisst.length < 3"></div>

          <!-- <div
            class="
              newspaper
              newspapertwo
              fadeInUp
              animatechangefive
              wow
              animate
              fadeInUp
              delay-300ms
            "
          >
            <div class="paperinner">
              <div class="paperhead">
                <div class="papertime">2021.05.20</div>
                <div class="paperfont">Company news</div>
              </div>
              <div class="fontbig">
                CULTURE RESEARCH INSTITYTE FAMGTAI IN CULTURE RESEARCH...
              </div>
              <div class="imgBox">
                <img
                  class="twoimgscopy"
                  src="../assets/img/alltitle/yx-02.jpg"
                />
                <img src="../assets/img/alltitle/video.png" class="videoIcon" />
                <div class="zz"></div>
              </div>
              <div class="keoutdoor">
                <div class="learnmore">Learn more</div>
              </div>
            </div>
          </div>

          <div
            class="
              newspaper
              newspapertwo
              fadeInUp
              animatechangesix
              wow
              animate
              fadeInUp
              delay-400ms
            "
          >
            <div class="paperinner">
              <div class="paperhead">
                <div class="papertime">2021.05.20</div>
                <div class="paperfont">Company news</div>
              </div>
              <div class="fontbig">
                FAMGTAI IN CULTURE RESEARCH INSTITYTE FAMGTAI IN CULTURE
                RESEARCH...
              </div>
              <div class="imgBox">
                <img
                  class="twoimgscopy"
                  src="../assets/img/alltitle/yx-03.jpg"
                />
                <img src="../assets/img/alltitle/video.png" class="videoIcon" />
                <div class="zz"></div>
              </div>
              <div class="keoutdoor">
                <div class="learnmore">Learn more</div>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div
        class="firstwird firstwirdtwo wow animate fadeInUp"
        v-if="thirdlist.length != 0"
      >
        <div class="firstcontent wow animate fadeInUp">
          {{ $t("login.gourmet") }}
        </div>
        <div class="secondcontent wow animate fadeInUp delay-200ms">
          <div class="context">
            {{ $t("login.cokintmast") }}
          </div>
          <!-- <div class="conarrow">
            <div class="conall">{{ $t("login.allting") }}</div>
            <img class="likearrow" src="../assets/img/alltitle/rowarrow.png" />
          </div> -->
        </div>
        <div class="theretext">
          <div
            class="newspaper newspapertwo wow animate fadeInUp delay-300ms"
            v-for="item in thirdlist"
            :key="item.id"
            @click="tomenudetail(item.id)"
          >
            <div class="paperinner">
              <div class="">
                <!-- <img
                  class="twoimgscopy twoimgscopyTWO"
                  src="../assets/img/alltitle/cp-01.jpg"
                /> -->
                <img class="twoimgscopy twoimgscopyTWO" :src="item.img" />
              </div>
              <div class="lastfont">
                <!-- THE PERFECT ENCOUNTER OF CHEESE AND EGGPLANT -->
                {{ item.title }}
              </div>
              <div class="lasttext">
                <!-- Fangtai Culture Research Institute is a non-profit pate nental
                organization, dissemin ... -->
                {{ item.intro }}
              </div>
              <div class="keoutdoor">
                <div class="learnmore learnmoretwo">
                  {{ $t("login.mostdetail") }}
                </div>
              </div>
            </div>
          </div>
          <div class="othernews" v-if="thirdlist.length < 3"></div>

          <!-- <div class="antohwidth"></div> -->
        </div>
      </div>
      <img
        v-if="
          firstlist.length == 0 &&
          secondlisst.length == 0 &&
          thirdlist.length == 0
        "
        class="nodataimg"
        src="../assets/img/alltitle/nodata.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import {
  getrecnewslist,
  getrecvideolist,
  getreccookbooklist,
} from "../request/api";
export default {
  data() {
    return {
      firstlist: [],
      secondlisst: [],
      thirdlist: [],
    };
  },
  created() {
    console.log(this.$route.query.id);
    console.log(this.$route.query.sid);
    console.log(this.$route.query.tid);

    this.firstlist = this.$route.query.id;
    this.secondlisst = this.$route.query.id;
    this.thirdlist = this.$route.query.id;

    if (sessionStorage.getItem("newlangusge") == null) {
      this.outrecnewslist("nz", "en", "d");
      this.outrecvideolist("nz", "en", "d");
      this.outreccookbooklist("nz", "en", "d");
    } else {
      this.outrecnewslist("nz", sessionStorage.getItem("newlangusge"), "d");
      this.outrecvideolist("nz", sessionStorage.getItem("newlangusge"), "d");
      this.outreccookbooklist("nz", sessionStorage.getItem("newlangusge"), "d");
    }
    setTimeout(() => {
      this.$route.meta.title = this.$t("login.querythere11");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    }, 10);
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      console.log(d);
      this.outrecnewslist(message, b, d);
      this.outrecvideolist(message, b, d);
      this.outreccookbooklist(message, b, d);
      this.$route.meta.title = this.$t("login.querythere11");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  methods: {
    outreccookbooklist(a, b, c) {
      getreccookbooklist({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.thirdlist = res.data;
      });
    },
    outrecvideolist(a, b, c) {
      getrecvideolist({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.secondlisst = res.data;
      });
    },
    outrecnewslist(a, b, c) {
      getrecnewslist({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.firstlist = res.data;
      });
    },

    todetail(a) {
      this.$router.push({ path: "/details", query: { id: a } });
    },
    tomenudetail(a) {
      console.log(111111);
      this.$router.push({ path: "/detailstomenu", query: { id: a } });
    },
    tovideodetail(a) {
      console.log(111111);
      this.$router.push({ path: "/detailstovideo", query: { id: a } });
    },
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
};
</script>
<style scoped lang='less'>
.alltitle {
  .fournavoutside {
    .navtitlehead {
      width: 100%;
      height: 70px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      .navtitleinner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 260px;
        display: flex;
        img {
          width: 20px;
          height: 20px;
        }
        .navtext {
          margin-left: 6px;
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          color: #000000;
          line-height: 24px;
        }
      }
    }
  }
  .allcontainer {
    width: 100%;
    background-color: #f2f2f2;
  }

  .centerwidth {
    width: 1200px;
    margin: 0 auto;
  }

  .firstwird {
    width: 1200px;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
  }

  .firstcontent {
    font-size: 28px;
    font-family: Arial Black;
    font-weight: 900;
    color: #000000;
    text-transform: uppercase;
  }

  .secondcontent {
    display: flex;
    /*background-color: red;*/
    justify-content: space-between;
    margin-top: 10px;
  }

  .context {
    font-size: 15px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #000000;
    line-height: 24px;
    opacity: 0.85;
    width: 1010px;
  }

  .conarrow {
    display: flex;
    justify-content: center;
    // align-items: center;
  }

  .conall {
    // vertical-align: middle;
    font-size: 13px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #000000;
    // line-height: 48px;
    cursor: pointer;
    margin-top: 6px;
  }

  .likearrow {
    margin-left: 11px;
    vertical-align: middle;
    width: 12px;
    height: 10px;
    margin-top: 9px;
  }

  .theretext {
    display: flex;
    margin-top: 42px;
    justify-content: space-between;
  }

  .newspaper {
    width: 386px;
    /* height: 300px; */
    background: #ffffff;
    border-radius: 7px;
    margin-bottom: 25px;
    padding-bottom: 0px;
    cursor: pointer;
    transition: transform 0.3s;
  }
  .othernews {
    width: 386px;
  }

  .newspaper:hover {
    transform: translate(0, -5px);
    box-shadow: 5px 8px 16px 0px rgba(8, 1, 3, 0.08);
  }

  /* .animatechangeone{
	-webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animatechangetwo{
	-webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animatechangethere{
	-webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animatechangefour{
	-webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animatechangefive{
	-webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animatechangesix{
	-webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
} */

  .newspapertwo {
    width: 386px;
    /* height: 407px!important; */
    background: #ffffff;
    border-radius: 7px;
    /*background-color: red;*/
  }

  .paperinner {
    margin: 18px 24px;
  }

  .paperhead {
    display: flex;
    width: 315px;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 12px;
  }

  .papertime {
    font-size: 12px;
    font-family: Helvetica Neue LT Pro;
    font-weight: normal;
    color: #474545;
    opacity: 0.35;
  }

  .paperfont {
    margin-left: 19px;
    font-size: 12px;
    /* font-family: Helvetica Neue LT Pro; */
    font-weight: normal;
    color: #474545;
    opacity: 0.35;
  }

  .fontbig {
    margin-top: 16px;
    /*display: flex;*/
    font-size: 15px;
    /* font-family: Arial; */
    font-weight: bold;
    color: #3c3c3c;
    line-height: 20px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-transform: uppercase;
    height: 42px;
  }

  .imgandfont {
    // display: flex;
    margin-top: 20px;
    flex-direction: row;
    .leftimgs {
      width: 82px;
      height: 66px;
      border-radius: 4px;
    }

    .rightfont {
      float: right;
      width: 220px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;

      font-size: 12px;
      color: #898989;
      // opacity: 0.75;
      // margin-left: 16px;
    }
  }

  .keoutdoor {
    display: flex;
    /* width: 315px; */
    justify-content: flex-end;
  }

  .learnmore {
    /* width: 65px; */
    margin-top: 12px;
    padding-bottom: 5px;
    font-size: 12px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #898989;
    line-height: 13px;
    /* border-bottom: 1px solid #AB1A30; */
    text-align: right;
  }

  .learnmore::after {
    content: "";
    display: block;
    background-color: #9e2b29;
    // width: 65px;
    height: 1px;
    margin-top: 4px;
    /* position: absolute; */
    font-size: 12px;
    transition: all 0.6s ease 0s;
  }

  .learnmore:hover::after {
    content: "";
    display: block;
    margin-top: 4px;
    background-color: #9e2b29;
    width: 0px;
    height: 1px;
    font-size: 12px;
    transition: all 0.6s ease 0s;
  }

  .learnmore:hover {
    color: #9e2b29;
  }

  .twoimgscopy {
  }

  .twoimgscopyTWO {
    margin-top: 10px;
    width: 100%;
    /* height: 170px; */
    background: #000000;
    /*opacity: 0.3;*/
    border-radius: 4px;
  }

  .lastfont {
    width: 100%;
    font-size: 16px;
    /* font-family: Arial; */
    font-weight: bold;
    color: #3c3c3c;
    line-height: 24px;
    margin-top: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .lasttext {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-top: 12px;
    width: 100%;
    font-size: 12px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #898989;
    line-height: 18px;
    opacity: 0.75;
  }

  .learnmoretwo {
    margin-top: 12px;
  }

  .firstwirdtwo {
    padding-bottom: 100px;
  }
}
.imgBox {
  position: relative;
  margin-top: 20px;
  overflow: hidden;
  margin-bottom: 10px;
  width: 93%;
  border-radius: 4px;
  .twoimgscopy {
    width: 100%;
    float: left;
  }
  .videoIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    width: 40px;
    z-index: 19;
  }
}
.imgBox .zz {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 97%;
  background: rgba(0, 0, 0, 0.2);
}
.mabewide {
  width: 386px;
}
.namethere {
  width: 338px;
  height: 180px;
  width: 100%;
}
.antohwidth {
  width: 386px;
}
.nodataimg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  vertical-align: middle;
  width: 160px;
  height: 100px;
  padding-bottom: 100px;
  padding-top: 100px;
}
</style>
