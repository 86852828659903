<template>
  <div class="debuage">
    <!-- :src="url" -->

    <iframe name="topIframe" width="100%" id="park" :src="url"></iframe>
  </div>
</template>
<script>
import { getiframeurl } from "../request/api";

export default {
  data() {
    return {
      mayheig: "",
      url: "",
    };
  },
  created() {
    console.log(this.$route.query.id);
    console.log(this.$route.query.type);
    console.log(this.$route.query.name);
    console.log(this.$route.query.cate);
    // this.url = this.$route.query.id;

    if (sessionStorage.getItem("newlangusge") == null) {
      this.getmeoute(
        "nz",
        "en",
        this.$route.query.type,
        this.$route.query.name,
        this.$route.query.cate
      );
    } else {
      this.getmeoute(
        "nz",
        sessionStorage.getItem("newlangusge"),
        this.$route.query.type,
        this.$route.query.name,
        this.$route.query.cate
      );
      // location.reload();
    }

    this.$bus.$on("add", (message, b, d) => {
      if (sessionStorage.getItem("newlangusge") == null) {
        this.getmeoute(
          "nz",
          "en",
          this.$route.query.type,
          this.$route.query.name,
          this.$route.query.cate
        );
      } else {
        this.getmeoute(
          "nz",
          sessionStorage.getItem("newlangusge"),
          this.$route.query.type,
          this.$route.query.name,
          this.$route.query.cate
        );
        // location.reload();
      }
    });
  },
  mounted() {
    const oIframe = document.getElementById("park");
    const deviceWidth = document.documentElement.clientWidth;
    const deviceHeight = document.documentElement.clientHeight;
    // oIframe.style.width = deviceWidth + "px";
    oIframe.style.height = deviceHeight + "px";

    console.log(oIframe.style.height);
  },
  methods: {
    getmeoute(a, b, c, d, e) {
      getiframeurl({
        lang: b,
        country: a,
        type: c,
        name: d,
        cate: e,
      }).then((res) => {
        console.log(res);
        this.url = res.data.url;
        // location.reload();
      });
    },
  },
};
</script>
<style scoped>
.debuage {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 937px;
  background: #ffffff;
  /* padding-left: -20px; */
  /* margin-left: -20px; */
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
iframe {
  width: 100%;
  border: none;
  position: absolute;
  right: -20px;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  /* overflow-y: hidden; */
}
</style>