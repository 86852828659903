module.exports = {
	
	language: {
	    name: 'simples'
	},
		
	login: {
		home: '首頁',
		producet: '產品',
		producets: '购物车',
		serives: '服務',
		news: '新聞',
		about: '關於',
		follow: '跟隨',
		rangehoods: '煙機',
		hobs: '竈具',
		lectricovens: '烤箱',
		steamovens: '蒸箱',
		micovens: '微波爐',
		dishwasher: '水槽洗碗機',
		allproduct:"所有產品",
		faq: 'FAQ',
		manualdownload: '手冊下載',
		exhibitionhallquery: '門店查詢',
		complaintjoin: '联系方太',
		complaintjoin1: '投诉',
		engineeing: '工程合作',
		aboutfotial: '關於方太',
		happykitchen: '幸福廚房',
		globalout: '全球分布',
		fotilenews : '方太新聞',
		fotilemedia: '方太映象',
		creativemenu : '創意菜譜',
		contentus : '聯系我們',
		contentusnow : '馬上聯系我們',
		cookinglife: '最先進的技術引導您通過快樂烹飪引領革命性的烹飪生活方式',
		allright : '方太海外廚房電器有限公司版權所有',
		sitemap : '網站地圖',
		legalnotice : '法律聲明',
		globalsite: '全球網站',
		biginning : 'FOTILE方太始終專註於廚房電器領域',
		nowhave : '在新西兰，现拥有吸油烟机、嵌入式灶具、嵌入式微波炉、嵌入式烤箱、嵌入式蒸箱、水槽洗碗机六大产品线。',
		happytochoose : '全球廚房的幸福選擇',
		allowaystodo : '方太壹直以來都致力於為人們提供高品質的產品和服務，打造健康環保的生活方式，讓更多的家庭享受幸福安心的廚房生活。過去20多年來，我們幫助全球各地的家庭改善廚房環境，守護廚房健康。為了億萬家庭的幸福，我們砥礪前行。',
		moreandmore: '更多',
		number1: '方太堅持每年將不少於銷售收入的5%投入研發，擁有包含廚房電器領域專家在內的近900余人的研發人才團隊。',
		number2: '方太目前在全國已有員工近16000余人，除雄厚的本土設計實力，方太還擁有來自韓、日等地的設計力量以及高端廚房生產設備及國際工業制造先進技術。',
		number3: '並於2013年被國家知識產權局評為第壹批國家級知識產權示範企業，積累了數十項廚電產品的核心技術。擁有7200*余件授權專利，其中發明專利超700*件，居行業翹楚地位。',
		number4: '方太擁有國家認定的企業技術中心和中國合格評定國家認可委員會認可的超過12000平方的實驗室，同時在德國、日本等地設立研究院。',
		newactive: '資訊活動',
		newmodel: '方太企業推行現代儒家管理模式 ，並積極投身傳統文化推廣、慈善、環保等公益事業，努力踐行企業社會公民責任。',
		newnext: '進壹步了解方太',
		newsersh: '專屬客服',
		newkonw: '想要了解更多資訊？我們會盡快安排專屬客服聯系您',
		xielipro: '系列產品',
		xingnum: '產品型號',
		conttype: '电话号码',
		submitsing: '提交',
		ochoosepro: '選擇產品',
		ochoosexinghoa: '選擇型號',
		contrast: '對比',
		dingyue: '訂閱',
		baoxiu: '保修卡',
		protobi: '產品對比',
		obempty: '清空',
		osure: '確認',
		mabymost: '最多三種類型比較',
		guige: '規格',
		qubie: '區別',
		shipinvideo: '視頻',
		dayijin: '打印機',
		masgomai: '立即購買',
		tobuy: '購買',
		features: '特征',
		prodetail: '產品詳情',
		teparnters: '技術參數',
		installation: '安裝場景',
		manual: '產品手冊',
		tuijianpro: '推薦產品',
		mandetai1: '手冊',
		mandetai2: '技術參數',
		mandetai3: '安裝指南',
		mandetai4: '保修政策',
		mandetai5: '產品註冊',
		mandetai6: '宣傳冊',
		cannotdoit: '暫無',
		downloadli: '下載',
		serisepro: '方太為您提供各系列產品在使用過程中常見問題解答',
		pleachoose: '請選擇',
		ochaxue: '查詢',
		allwholeworld: '全球分布',
		wholedetail: '方太在成為世界頂級廚房電器專家的道路上壹步步前進，已進入分布於北美、歐洲、澳大利亞、亞洲和非洲的30多個國家。',
		tojoinwant: '留言',
		tothankyou: '有问题可以留言也可以电话，还可以选择我们的线上客服聊天',
		inputname1: '公司名稱',
		inputname2: '成立時間',
		inputname3: '公司地址',
		inputname4: '姓名',
		inputname5: '手機',
		inputname6: '邮箱',
		inputname7: '留言',
		inputname8: '主營產品',
		casesone: '認識方太工程',
		casesoneson: '自2005年，方太前瞻性地組建專業的工程營銷團隊， 全面切入房地產精裝修領域。在與眾多房地產企業合作的同時， 通過不斷挖掘客戶需求並進行自我完善和提升， 已經成為擁有眾多精裝修市場戰略客戶， 獲取眾多精裝修項目的廚電品牌。 截止目前，方太在全國近兩百多個大、中、小城市，配合上千家房地產企業， 完美交付近萬個精裝修項目， 並與160多家全國知名房地產企業結成戰略聯盟， 這些驕人的成績還在不斷被刷新！',
		casestwo: '經典項目案例',
		casestwoson: '截止目前，方太在全國近兩百多個大、中、小城市，配合上千家房地產企業， 完美交付近萬個精裝修項目， 並與160多家全國知名房地產企業結成戰略聯盟， 這些驕人的成績還在不斷被刷新！',
		casesthere: '廚電項目合作',
		casesthereson: '如有合作意向，請填寫聯系信息，我們將會盡快與您聯系',
		oinputname1: '稱呼：',
		oinputname2: '公司信息：',
		oinputname3: '語言：',
		oinputname4: '聯系電話：',
		oinputname5: '郵箱：',
		Engineering: '認識方太',
		calssing: '工程案例',
		parkservise: '工程無憂服務',
		shouafter: '工程服務流程',
		gorgeous: '我們自有專業工程項目服務團隊',
		authority: '從立項勘查、產品安裝直止業主交付，全程服務人員均為方太專業工程項目服務人員，具有強大的現場協調能力和標準化服務執行力。',
		lens: '專業項目服務經理跟蹤管理',
		erosion: '項目管理、批量施工',
		fatal: '高效的項目管理模式',
		motive: '從施工環境勘查、到貨協調、施工質量控制到現場環境對接 具有能夠滿足客戶需求的全過程項目管理體系。',
		cicumstance: '項目負責制',
		embody: '科學高效的項目管理體系',
		mutiply: '與供應鏈的無縫對接',
		wresetle: '自有服務團隊直通全國供應鏈系統，確保優秀的產品質量，及時的貨物交付。',
		pasture: '強大的交付保障',
		approval: '確保到貨及時',
		handing: '專業的工程服務現場管理標準',
		morning: '具有行業內嚴苛的質量控制體系、材料標準和現場管理標準，配合自有服務團隊的高效執行力，打造行業內良好且省心的工程服務。',
		handsed: '環境標準',
		hairsing: '施工標準',
		ducking: '材料工具標準',
		bite: '流程標準',
		rabbit: '快速高效的現場反饋機制',
		cakeing: '項目經理負責制，全過程項目管理配合，確保現場反饋快速高效。',
		badanmad: '項目經理制',
		smalling: '全過程全責',
		trutle: '創“3C無憂工程”服務流程',
		weeksing: '用方太靈動、貼心、全面的專業服務來實現讓我們的夥伴獲得更大價值空間、享受完善合作體驗的承諾：',
		jscket: '個性支持',
		shirting: '全心安裝',
		sweater: '滿意保障',
		hurtsing: '滿意保證',
		potato: '業主入住後的任何咨詢、維修申請、投訴和建議均可通過呼叫中心處理，開發商無需擔心。',
		tomato: '九大系列產品（抽油煙機、竈具、消毒櫃、蒸微壹體機、蒸籠、烤箱、微波爐、洗碗機、熱水器）的維修期均為五年。',
		Presales: '售前',
		Aftersale: '售後',
		Onsale: '售中',
		Presales1: '立項勘察',
		Presales2: '環境校正',
		Presales3: '選型方案',
		Presales4: '預埋煙管',
		Presales11: '專業的服務技術工程師在項目立項前後進行多次環境勘查，及時發現可能影響業主使用體驗的環境問題；',
		Presales12: '結合勘查結果和客戶需求，為客戶提供經濟有效的環境校正方案並在校正過程中持續進行免費的環境勘查與服務支持工作；',
		Presales13: '根據客戶需求和項目情況，為客戶提供多種廚房解決方案，並進行及時專業的技術支持；',
		Presales14: '根據精裝進度提供免費的煙管預埋服務，確保業主使用體驗。',
		Onsale1: '施工組織方案',
		Onsale2: '現場配合',
		Onsale3: '工廠直供',
		Onsale4: '交付配合',
		Onsale5: '營銷支持',
		Onsale11: '施工前完成專業的施工組織方案交底，有效規避施工過程可能遇到的問題，提高現場配合效率；',
		Onsale12: '專業的項目服務工程師全過程協調現場施工，配合現場工作確保施工質量；',
		Onsale13: '工廠直達項目工地的貨物供應機制，確保產品品質並有效降低物流帶來的貨品損耗，保障到貨及時；',
		Onsale14: '全過程配合業主交付，提供必要的產品使用說明及服務支持，提高客戶的業主滿意度；',
		Onsale15: '配合項目營銷需求，為業主提供高質量的施工過程標準、產品使用、服務承諾講解等服務支持。',
		Aftersale1: '全天候的至誠關懷服務',
		Aftersale2: '五年包修',
		Aftersale11: '至誠關懷服務汲取儒家文化的精髓，以“誠”為核心出發點，致力於為客戶提供及時、專業、用心的服務體驗，讓千萬家庭“家”的感覺更好；',
		Aftersale12: '油煙機、竈具、消毒櫃、蒸微壹體機、蒸箱、烤箱、微波爐、水槽洗碗機、熱水器產品整機包修5年，業主使用更安心。',
		
		allting: '更多',
		informationing: '資訊&活動',
		squareimage: '方太影像',
		gourmet: '創意菜譜',
		fantings: '過去20多年來，我們幫助全球各地的家庭改善廚房環境，守護廚房健康。為了億萬家庭的幸福，我們砥礪前行。',
		cokintmast: '方太致力於為您提供舒適安心的烹飪空間，為您和家人的健康保駕護航。',
		mostdetail:'詳情',
		about1:'全球廚房的幸福選擇',
		about11:'方太壹直以來都致力於為人們提供高品質的產品和服務，打造健康環保的生活方式，讓更多的家庭享受幸福安心的廚房生活。過去20多年來，我們幫助全球各地的家庭改善廚房環境，守護廚房健康。為了億萬家庭的幸福，我們砥礪前行。',
		about2:'方太研發與創新',
		about22:'作為廚電領域的領導者，方太擁有來自世界各地頂尖的研發和設計力量，高端廚房生產設備，國際工業制造先進技術，且在德國、日本和中國等地均設有研發中心。截至 2021 年 3 月，方太擁有 7200 余項授權專利，其中發明專利數量超 700 項，為行業翹楚。雄厚的科研力量，確保了方太的創新實力。2007 年至今，方太共獲得28 項 iF 大獎和 22 項 reddot 紅點大獎等國際獎項，成為行業佼佼者。',
		about3:'健康廚房生活',
		about33:'方太心系每壹位用戶的健康。我們堅信，廚房不只是壹個完成壹日三餐的場所，更是壹個家人壹起探索烹飪樂趣，分享幸福生活，傳遞愛與歡樂的空間。每壹頓飯也不僅僅是生活所需，更是每壹位偉大的家庭廚師生活態度的表達。方太致力於為您提供舒適安心的烹飪空間，為您和家人的健康保駕護航。',
		about4:'行業領導品牌',
		about44:'方太專註於高端廚電的研發與生產，擁有完整的廚電產品線，包括油煙機、竈具、蒸箱、烤箱、水槽洗碗機等，是全球30多個國家超過1700萬家庭的首選廚電品牌。截至 2020 年，方太以獨特的創新模式、深厚的品牌價值，連續七年榮登亞洲品牌 500 強，位居廚電行業第壹。',
		
		registering:'客戶信息',
		nottoregis:'方太不會把妳的個人信息透漏給任何壹方',
		kehunews1:'姓',
		kehunews2:'名',
		kehunews3:'電話號碼',
		kehunews4:'電子郵件',
		kehunews5:'地址',
		kehunews6:'公寓/套房/等',
		kehunews7:'城市/城鎮',
		kehunews8:'狀態',
		kehunews9:'郵政編碼',
		kehunews10:'選擇類別',
		kehunews11:'選擇型號',
		kehunews12:'產品序列號',
		kehunews13:'妳在哪裏購買方太',
		kehunews14:'直接選擇或者搜索選擇',
		kehunews15:'購買日期',
		kehunews16: '經銷商/亞馬遜發票/訂單號',
		nowtoregister:'現在註冊',
		personal:'個人信息',
		toubuy:'購買產品',
		halsthy:'健康廚房生活',
		alwaysanli:'方太壹直以來都致力於為人們提供高品質的產品和服務，打造健康環保的生活方式，讓更多的家庭享受幸福安心的廚房生活。',
		joninene:'註冊訂閱方太簡報',
		ionudd:'註冊我們的郵件，接收新產品提醒、特別優惠和優惠碼。',
		
		joininn:'加入',
		seriesing:'系列介紹',
		
		
		food1: '低卡主食 | 培根牛油果三明治',
		food2:'低卡早餐 | 韭菜蛋饼',
		food3:'低卡蔬果汁 | 西蓝花梨汁',
		food4:'深夜食堂 | 热量超低的春雨沙拉',
		food5:'创意美食 | 茼蒿土豆糙米饭团',
		

		newadd1:'热卖产品',
		newadd2: '本周销量最高产品推荐',
		

		feedback1: '名字',
		feedback2: '国家',
		feedback3: '城市',
		feedback4: '电话',
		feedback5: '描述',
		wantfaku:'我想要反馈建议',
		wantfakuson: '对您给予的帮助和支持，深表感谢！',
		tofaku: '建议反馈',
		
shiname:'FOTILE 是一个使命、愿景和价值驱动的企业。',
		hospion:'主席兼总裁：',

		
borderred:'请填写该字段',
sucssfil:'提交成功',


		mabuak: '消费者服务',
bighand:'大手册',


storeing: '门店',
		onlneing: '在线',
		fangdi:'房地产',



newthis:'蒸箱菜谱',



querythere1:'方太',
querythere2:'产品列表',
querythere3:'对比',
querythere4:'产品详情',
querythere6:'手冊下載',
querythere7:'門店查詢',
querythere8:'聯系方太',
querythere9:'投訴',
querythere10:'工程合作',
querythere11:'方太新聞',
querythere12:'方太影像',
querythere13:'創意菜譜',
querythere14:'工程合作详情',
querythere15:'方太新聞详情',
querythere16:'方太影像详情',
querythere17:'創意菜譜详情',
querythere18:'關于方太',
querythere19:'幸福廚房',
querythere20:'保修卡',



cancel:'取消',







	}
}
